<template>
  <v-btn
    :text="isText"
    :depressed="!isText"
    :type="type"
    :color="color"
    :loading="loading"
    v-bind="{ ...styleBindings, ...$attrs }"
    v-on="$listeners"
  >
    <slot name="icon">
      <v-icon
        v-if="icon"
        :size="iconSize"
        :color="iconColor"
        :class="iconMargin"
        >{{ icon }}</v-icon
      >
    </slot>
    <slot>{{ $t(label) }}</slot>
  </v-btn>
</template>
<script>
const roles = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
}
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    role: {
      type: String,
      default: 'primary',
      validator: (value) => Object.values(roles).includes(value),
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    iconMargin: {
      type: String,
      default: 'mr-2',
    },
    iconColor: {
      type: String,
      default: 'inherit',
    },
    iconSize: {
      type: Number,
      default: 14,
    },
  },
  computed: {
    color() {
      if (this.role === roles.PRIMARY) {
        return 'primary'
      }
      return null
    },
    styleBindings() {
      if (this.role === roles.PRIMARY) {
        return {
          color: 'primary',
        }
      }
      return {
        class: 'primary--text',
      }
    },
    isText() {
      return this.role === roles.TEXT
    },
  },
}
</script>
