
<d-modal>
  <d-modal-title :hide-close-button="inList" @close="emit('cancel')">
    <h5 class="text-h5">
      {{ dialogTitle || $t('news-dialog:dialogTitle') }}
    </h5>
  </d-modal-title>
  <d-modal-content spacing="px-0">
    <a :href="url" target="_blank" rel="noopener" class="block">
      <img :src="imageSrc" class="news-image" />
    </a>
    <div class="news-content pb-0 pt-12">
      <div v-if="date" class="text--secondary text-center">
        {{ formatDate(date) }}
      </div>
      <h5 class="text-h4 text-center mb-6">{{ title }}</h5>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="news-text" v-html="description" />
    </div>
  </d-modal-content>
  <d-modal-actions>
    <div
      v-if="url"
      class="d-flex justify-center news-buttons"
      style="width: 100%"
    >
      <d-btn
        large
        class="news-button"
        type="submit"
        label="news-dialog:more"
        :href="url"
        target="_blank"
        :role="inList ? 'text' : 'primary'"
        @click="emit('cancel')"
      />
    </div>
  </d-modal-actions>
</d-modal>
