
<v-btn
  :text="isText"
  :depressed="!isText"
  :type="type"
  :color="color"
  :loading="loading"
  v-bind="{ ...styleBindings, ...$attrs }"
  v-on="$listeners"
>
  <slot name="icon">
    <v-icon
      v-if="icon"
      :size="iconSize"
      :color="iconColor"
      :class="iconMargin"
      >{{ icon }}</v-icon
    >
  </slot>
  <slot>{{ $t(label) }}</slot>
</v-btn>
