const initialState = () => ({
  updatedContext: { field: null, value: null },
})

const mutations = {
  sidepanelSetContext(state, field) {
    state.updatedContext.field = Object.keys(field)[0]
    state.updatedContext.value = Object.values(field)[0]
  },
}

const actions = {
  sidepanelLoadContext({ commit }, state) {
    commit('sidepanelSetContext', state)
  },
}

const getters = {
  sidepanelGetUpdatedContext: ({ updatedContext }) => updatedContext,
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
}
