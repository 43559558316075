import moment from 'moment'
import { pipe } from './functional'

export const dateStr = (dateTime = undefined) =>
  moment(dateTime).format('YYYY-MM-DD')

export const timeStr = (dateTime = undefined) =>
  moment(dateTime).format('kk:mm')

export const dateTimeStr = (dateTime = undefined) =>
  moment(dateTime).format('YYYY-MM-DD kk:mm:ss')

export const fromToTimeStr = ({ start, end }) =>
  `${timeStr(start)} – ${timeStr(end)}`

export const dateFromStr = (date) => moment(date, 'YYYY-MM-DD')
export const timeFromStr = (time) => moment(time, 'kk:mm')

export const userTimeInputParse = (input) => {
  const matches = moment(input, ['HH:mm', 'H:m', 'HH:m', 'H:mm'])
  if (matches.isValid()) {
    return timeStr(matches)
  } else {
    return false
  }
}

export const momentFromStrs = (date = null, time = null) =>
  moment(`${date || dateStr()} ${time || timeStr()}`, 'YYYY-MM-DD kk:mm')

export const addMinutesToTimeStr = pipe(
  (time, minutes) => timeFromStr(time).add(minutes, 'minutes'),
  timeStr
)

export const timeStrMinutesDifference = pipe(
  ({ start, end }) => ({ start: timeFromStr(start), end: timeFromStr(end) }),
  ({ start, end }) => end.diff(start, 'minutes')
)

export const dateStrWeekFromTo = (date) => ({
  from: dateStr(dateFromStr(date).startOf('isoWeek')),
  to: dateStr(dateFromStr(date).endOf('isoWeek')),
})

export const inDays = (days = 1) => moment().add(days, 'days')
export const daysAgo = (days = 1) => moment().subtract(days, 'days')

export const isBetweenFromDaysInDays = (subject, fromDays = 5, toDays = 5) =>
  moment(subject).isBetween(daysAgo(fromDays), inDays(toDays))

export const formatDate = (dateTime, format = 'L') =>
  moment(dateTime).format(format)
