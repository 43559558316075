
<v-text-field
  v-model.trim="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  v-on="defaultHandlers"
>
  <template #message="{ message }">
    <slot :name="message"></slot>
  </template>
</v-text-field>
