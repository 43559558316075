// 2FA
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION'

// Filling Assistant
export const SET_TREATMENT_FILLING_ASSISTANT = 'SET_TREATMENT_FILLING_ASSISTANT'
export const SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT =
  'SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT'
export const SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT =
  'SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT'
export const SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT =
  'SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT'
export const SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT =
  'SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT'
