<template>
  <v-tabs
    v-model="selectedTab"
    color="transparent"
    active-class="primary--text v-tabs__item--active"
    slider-color="primary"
  >
    <d-single-tab v-for="tab in tabs" :key="tab" :tab="tab" />
  </v-tabs>
</template>

<script>
import indexOf from 'lodash/indexOf'

export default {
  props: {
    tabs: Array,
    activeTab: String,
    tabType: String,
    event: String,
  },
  computed: {
    selectedTab: {
      get() {
        const { tabs, activeTab } = this
        return indexOf(tabs, activeTab)
      },
      set(tabIndex) {
        const { tabType, tabs } = this
        const tab = tabs[tabIndex]
        this.$emit(this.event, { tab, tabType })
      },
    },
  },
}
</script>
