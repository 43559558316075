
<div class="search-overlay">
  <button
    class="search-overlay__btn"
    role="button"
    @click.prevent="initSearchHandler"
  >
    <svg class="navigation__icon" focusable="false">
      <use xlink:href="#icon-search" />
    </svg>
  </button>

  <transition name="trs-fade-out" mode="in-out">
    <div v-if="isActive" class="search-overlay__body">
      <div class="search-overlay__overlay" @click="closeSearchHandler" />
      <div class="search-overlay__content">
        <quick-command :is-active="isActive" @cancel="closeSearchHandler" />
      </div>
    </div>
  </transition>
</div>
