<template>
  <div class="d-searchable-dropdown">
    <d-autocomplete
      auto-select-first
      :clearable="false"
      :append-icon="'mdi-chevron-down'"
      :height="'44px'"
      :hide-details="true"
      :allow-new-item="false"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
.d-searchable-dropdown {
  // Vars

  // Support

  // Module
  & {
    .v-input {
      border-radius: 4px;
    }

    ::v-deep {
      .v-menu__content {
        max-width: none !important;
      }

      .v-list-item__mask {
        color: #000 !important;
        background-color: var(--green-light) !important;
      }

      .v-input__control .v-input__slot {
        min-height: 0;
        &:before,
        &:after {
          display: none;
        }

        label.v-label--active {
          top: 13px;
          transform: translateY(-8px);
          margin-bottom: 2px;
          font-size: 10px;
          letter-spacing: 0.5px;
          color: #9e9e9e;
        }

        .v-select__slot input {
          width: auto;
          min-width: 64px;
          padding-bottom: 6px;
          font-size: 11.3px;
          text-transform: uppercase;
          letter-spacing: 0.0892857143em;
        }

        .v-input__append-inner {
          margin-top: 10px;
          margin-right: -7px;

          .v-icon {
            color: inherit;
          }
        }
      }
    }
  }
}
</style>
