// https://vuejs.org/v2/cookbook/adding-instance-properties.html
import Vue from 'vue'
import featureFlag from '@/services/feature-flag'
import { viewHelpers } from './viewHelpers'

// Event bus to emit some events on global level
Vue.prototype.$eventBus = new Vue()

// Feature flags
featureFlag.apply(window.FeatureFlags)
Vue.prototype.$featureFlag = window.FeatureFlag = featureFlag

// View helpers
// In Vue3, these can become global properties
// https://v3-migration.vuejs.org/breaking-changes/filters#migration-strategy
Vue.prototype.$helpers = viewHelpers
