import fromPairs from 'lodash/fromPairs'
import moment from 'moment'
import { dateStr, timeStr, dateTimeStr } from '@/libs/dateTime'
import {
  modes,
  actionStateTypes,
  viewTypeWeekdays,
  viewTypes,
  customViewTypes,
  columnTypes,
  columnTypeGroups,
} from '@/types/calendar'

const actionStatesFromTypes = () => {
  const actionStates = actionStateTypes.map((key) => [
    key,
    { active: false, event: { id: null } },
  ])
  return fromPairs(actionStates)
}

export default {
  mode: modes.CALENDAR,
  physicians: [],
  calendarResources: [],
  calendarRooms: [],
  eventTypes: [],
  /*
    events: { calendar: { single: [], recurring: []}, historic: { single: [], recurring: []}, planned: { single: [], recurring: []} }
  */
  events: Object.values(modes).reduce(
    (obj, mode) => ({ ...obj, [mode]: { single: [], recurring: [] } }),
    {}
  ),
  columns: {
    people: { selectedItems: [], group: columnTypeGroups.default },
    rooms: { selectedItems: [], group: columnTypeGroups.default },
    type: columnTypes.people,
  },
  reminderContents: {
    email: null,
    subject: null,
    sms: null,
  },
  businessHours: [],

  // v-calendar options
  options: {
    now: dateTimeStr(),
    nowTime: timeStr(),
    viewType: viewTypes.week,
    customViewType: customViewTypes.work_week,
    weekdays: [...viewTypeWeekdays[customViewTypes.work_week]],
    range: {
      start: dateStr(moment().startOf('week')),
      end: dateStr(moment().day(5)),
    },
    locale: 'de',
    interval: {
      first: 0, // first interval displayed
      count: 48, // amount of intervals displayed
      minutes: 30, // minutes per interval
      height: 30, // height in pixel
    },
  },
  actionStates: {
    snackbar: {
      active: false,
      action: null,
    },
    ...actionStatesFromTypes(),
  },
}
