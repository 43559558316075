export const createAbortController = () => {
  let controller: AbortController = null

  return {
    isAbortable: false,
    setAbortable(flag: boolean) {
      this.isAbortable = flag
      if (this.isAbortable) {
        controller = new AbortController()
      }
    },
    getSignal() {
      return this.isAbortable && controller ? controller.signal : undefined
    },
    abort() {
      if (this.isAbortable && controller) {
        controller.abort()
      }
    },
    reset() {
      // Reset the controller after aborting the previous one
      if (this.isAbortable) {
        controller = new AbortController()
      }
    },
  }
}
