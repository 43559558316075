export default {
  props: {
    highlightNewRows: {
      type: Boolean,
      default: false,
    },
    scrollToNewRows: {
      type: Boolean,
      default: false,
    },
    /**
     * Provide unfiltered items so that the highlighting logic can ignore changes in the items array caused by filtering only
     */
    unfilteredItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      highlightedItems: [],
      slowlyFadingItems: [],
      previousUnfilteredItems: [],
    }
  },
  computed: {
    previousUnfilteredItemsFlat() {
      return this.flattenItems(this.previousUnfilteredItems)
    },
    newItemWasAdded() {
      return (
        this.determineNewItems(
          this.unfilteredItems,
          this.previousUnfilteredItems
        ).length > 0
      )
    },
    unfilteredItemsPresent() {
      return this.unfilteredItems.length > 0
    },
  },
  methods: {
    isItemFadingSlowly(item) {
      if (!this.highlightNewRows) return false

      return this.slowlyFadingItems.find(
        (slowlyFadingItem) => slowlyFadingItem.id === item.id
      )
    },
    isItemHighlighted(item) {
      if (!this.highlightNewRows) return false

      return this.highlightedItems.find(
        (highlightedItem) => highlightedItem.id === item.id
      )
    },
    highlightAndScrollToNewItems(currentItems = [], oldItems = []) {
      if (!this.highlightNewRows && !this.scrollToNewRows) return

      const sectionFilter = (item) => item.isSection
      const newSectionWasAdded =
        currentItems.filter(sectionFilter).length >
        oldItems.filter(sectionFilter).length
      if (
        !newSectionWasAdded &&
        this.unfilteredItemsPresent &&
        !this.newItemWasAdded
      )
        return

      const newItems = this.determineNewItems(currentItems, oldItems)
      this.previousUnfilteredItems = [...this.unfilteredItems]

      if (this.highlightNewRows) this.highlightItems(newItems)
      if (this.scrollToNewRows) this.scrollToItem(newItems[0])
    },
    flattenItems(items) {
      return items.flatMap((item) => [item, ...this.getNestedItems(item)])
    },
    determineNewItems(currentItems, oldItems) {
      const currentItemsFlat = this.flattenItems(currentItems)
      const oldItemsFlat = this.flattenItems(oldItems)

      return currentItemsFlat.filter(
        (currentItem) =>
          !oldItemsFlat.some(
            (oldItem) => currentItem[this.itemId] === oldItem[this.itemId]
          )
      )
    },
    highlightItems(items) {
      this.slowlyFadingItems = []
      this.highlightedItems = [...items]

      // remove highlighting after defined delay, fading out slowly
      setTimeout(() => {
        this.slowlyFadingItems = [...this.highlightedItems]
        this.highlightedItems = []
      }, 5000)
    },
    scrollToItem(item) {
      if (!item) return

      this.$nextTick(() => {
        const targetRowNode = [
          ...this.$refs.items,
          ...(this.$refs?.nestedItems || []),
        ].find((rowNode) => rowNode.id === item[this.itemId].toString())

        const boundingRect = targetRowNode.getBoundingClientRect()
        if (boundingRect.bottom > window.innerHeight || boundingRect.top < 0)
          targetRowNode.scrollIntoView({ behavior: 'smooth' })
      })
    },
  },
}
