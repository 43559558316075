import { createNamespacedHelpers } from 'vuex'
import teamMemberService from '../../../services/team-member'
import locationService from '../../../services/location'
import { prerenderedState } from '@/store/helpers'

export const { mapState, mapActions, mapMutations, mapGetters } =
  createNamespacedHelpers('common')

/**
 * InitialState which are is used to initialize the store. Additionally
 * prerenderetState takes DenteoInitialStoreState from the html and uses
 * it to prerender the state.
 *
 * @param availableLocations Locations accessible to the user. This is prerendered.
 * @param financiallyAvailableLocations Financially available locations to the user. This is prerendered.
 * @param activeLocations All locations active in the practice without archived ones. This is prerendered.
 * @param allLocations All locations including archived ones. This is prerendered.
 * @param currentLocationId Is used to preselect location-select, which is a filter. Not to confuse with lastLocationSelectedId.
 * @param currentFinancialLocationId Is used to preselect location-select, which is a filter. Not to confuse with lastLocationSelectedId.
 * @param lastLocationSelectedId is used to preselect d-input-location, which is a input. Not to confuse with currentLocationId.
 */
const initialState = () => ({
  teamMembers: [],
  user: null,
  availableLocations: [],
  financiallyAvailableLocations: [],
  activeLocations: [],
  allLocations: [],
  currentLocationId: null,
  currentFinancialLocationId: null,
  isAllLocationOptionSelected: null,
  lastLocationSelectedId: null,
  // this is really just called ONCE on the first page load, page changes via turbolinks will not call this
  // this can lead to desync - data that are held in the storage are no longer valid
  // we should update the state with every page change
  ...prerenderedState('common'),
})

const actions = {
  async loadTeamMembers({ commit }) {
    const { team_members } = await teamMemberService.fetchAll()
    commit('SET_TEAM_MEMBERS', team_members)
  },
  async initialize({ commit, dispatch, state }) {
    if (state.initialized) {
      return
    }
    await dispatch('loadTeamMembers')

    commit('SET_INITIALIZED')
  },
  async loadLocations({ commit }, params = {}) {
    const { locations: availableLocations } = await locationService.fetchAll({
      accessible_locations: true,
      detailed: true,
      ...params,
    })
    const { locations: financiallyAvailableLocations } =
      await locationService.fetchAll({
        financially_accessible_locations: true,
        detailed: true,
        ...params,
      })
    const { locations: activeLocations } = await locationService.fetchAll({
      detailed: true,
      ...params,
    })
    const { locations: allLocations } = await locationService.fetchAll({
      archived: true,
      detailed: true,
      ...params,
    })

    commit('SET_AVAILABLE_LOCATIONS', availableLocations)
    commit('SET_FINANCIALLY_AVAILABLE_LOCATIONS', financiallyAvailableLocations)
    commit('SET_ACTIVE_LOCATIONS', activeLocations)
    commit('SET_ALL_LOCATIONS', allLocations)
  },
  setCurrentLocationId: ({ commit }, locationId) => {
    window.localStorage.setItem('currentLocationId', locationId)
    window.localStorage.setItem('isAllLocationOptionSelected', false)
    commit('SET_CURRENT_LOCATION_ID', locationId)
    commit('SET_IS_ALL_LOCATION_OPTION_SELECTED', false)
  },
  setCurrentFinancialLocationId: ({ commit }, locationId) => {
    window.localStorage.setItem('currentFinancialLocationId', locationId)
    commit('SET_CURRENT_FINANCIAL_LOCATION_ID', locationId)
  },
  setIsAllLocationOptionSelected: ({ commit }, value) => {
    window.localStorage.setItem('isAllLocationOptionSelected', value)
    commit('SET_IS_ALL_LOCATION_OPTION_SELECTED', value)
  },
  setLastLocationSelected: ({ commit }, locationId) => {
    window.localStorage.setItem('lastLocationSelected', locationId)
    commit('SET_LAST_LOCATION_SELECTED', locationId)
  },
}

const mutations = {
  SET_LAST_LOCATION_SELECTED(state, locationId) {
    state.lastLocationSelectedId = locationId
  },
  SET_TEAM_MEMBERS(state, teamMembers) {
    state.teamMembers = teamMembers
  },
  SET_INITIALIZED(state) {
    state.initialized = true
  },
  SET_AVAILABLE_LOCATIONS: (state, locations) =>
    (state.availableLocations = locations),
  SET_FINANCIALLY_AVAILABLE_LOCATIONS: (state, locations) =>
    (state.financiallyAvailableLocations = locations),
  SET_ACTIVE_LOCATIONS: (state, locations) =>
    (state.activeLocations = locations),
  SET_ALL_LOCATIONS: (state, locations) => (state.allLocations = locations),
  SET_CURRENT_LOCATION_ID: (state, locationId) =>
    (state.currentLocationId = locationId),
  SET_CURRENT_FINANCIAL_LOCATION_ID: (state, locationId) =>
    (state.currentFinancialLocationId = locationId),
  SET_IS_ALL_LOCATION_OPTION_SELECTED: (state, value) =>
    (state.isAllLocationOptionSelected = value),
}

const getters = {
  teamMembers: (state) => {
    return state.teamMembers || []
  },
  practitioners: (state) =>
    state.teamMembers.filter((teamMember) =>
      teamMemberService.isPractitioner(teamMember)
    ),
  userIsPractitioner: (state, getters) =>
    getters.userIsTeamMember && teamMemberService.isPractitioner(state.user),
  userIsTeamMember: (state) =>
    state.teamMembers.some((teamMember) => teamMember.id === state.user.id),
  teamMemberById:
    ({ teamMembers }) =>
    (teamMemberId) =>
      teamMembers.find((teamMember) => teamMember.id === teamMemberId),
  defaultTeamMemberId: (state, getters) =>
    getters.teamMembers.length < 1
      ? null
      : getters.userIsTeamMember
      ? state.user.person_id
      : getters.teamMembers[0].id,
  userId: (state) => state.user.person_id,
  defaultPhysicianId: (state, getters) => {
    if (getters.userIsPractitioner) {
      return getters.userId
    } else {
      return getters.practitioners[0].id
    }
  },
  locationById:
    ({ allLocations }) =>
    (locationId) =>
      allLocations.find(({ id }) => id === locationId),
  getCurrentLocationId: ({ availableLocations, currentLocationId }) => {
    const numericId =
      currentLocationId ||
      parseInt(window.localStorage.getItem('currentLocationId'))

    // check if stored location is still available for the current user (permission might have been removed)
    const allowedLocation = availableLocations.find(
      (location) => location.id === numericId
    )

    if (allowedLocation) return numericId

    return (
      availableLocations &&
      availableLocations.length > 0 &&
      availableLocations[0].id
    )
  },
  getCurrentFinancialLocationId: ({
    financiallyAvailableLocations,
    currentFinancialLocationId,
  }) => {
    const numericId =
      currentFinancialLocationId ||
      parseInt(window.localStorage.getItem('currentFinancialLocationId'))

    // check if stored location is still available for the current user (permission might have been removed)
    const allowedLocation = financiallyAvailableLocations.find(
      (location) => location.id === numericId
    )

    if (allowedLocation) return numericId

    return (
      financiallyAvailableLocations &&
      financiallyAvailableLocations.length > 0 &&
      financiallyAvailableLocations[0].id
    )
  },
  // property for <location-select> component
  getCurrentLocationIdOrAll: (
    { isAllLocationOptionSelected },
    { getCurrentLocationId }
  ) => {
    const locationIdOrAll =
      isAllLocationOptionSelected === null
        ? JSON.parse(window.localStorage.getItem('isAllLocationOptionSelected'))
        : isAllLocationOptionSelected
    return locationIdOrAll ? null : getCurrentLocationId // null represents that the "all" location option is selected
  },
  // returns null if default location is not accessible by the the current user
  defaultLocationOrNull({ availableLocations }) {
    return availableLocations.find((location) => location.isDefault) || null
  },
  accessibleDefaultLocation({ availableLocations }, { defaultLocationOrNull }) {
    if (defaultLocationOrNull) return defaultLocationOrNull
    else return availableLocations[0]
  },
  // can be used to init the state when using the <d-input-location> component
  lastLocationSelected(
    { lastLocationSelectedId, availableLocations },
    { accessibleDefaultLocation }
  ) {
    const locationId =
      lastLocationSelectedId ||
      parseInt(window.localStorage.getItem('lastLocationSelected'), 10)

    if (!locationId) return accessibleDefaultLocation.id
    else {
      const selectedLocationIsAccessible = availableLocations
        .map((location) => location.id)
        .includes(locationId)

      return selectedLocationIsAccessible
        ? locationId
        : accessibleDefaultLocation.id
    }
  },
  hasMultipleLocations: ({ availableLocations }) =>
    availableLocations.length > 1,
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
}
