<template>
  <v-alert v-bind="{ ...defaultAttrs, ...$attrs }" v-on="$listeners">
    {{ message }}
  </v-alert>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      defaultAttrs: {
        color: 'alert',
      },
    }
  },
}
</script>
