<template>
  <div>
    <v-autocomplete
      v-model="model"
      v-bind="defaultInputBindings"
      :items="items"
      :loading="loading"
      :search-input.sync="search"
      :no-data-text="noDataTextTranslated"
      :cache-items="false"
      :class="cssClassName"
      item-text="label"
      item-value="id"
      no-filter
      v-on="defaultHandlers"
      @input="$emit('input', $event)"
    >
    </v-autocomplete>
  </div>
</template>
<script>
import guarantorService from '../../../services/guarantor'
import dInputAutocompleteMixin from './d-input-autocomplete-mixin'
import dInputComponentMixin from './d-input-component-mixin'

const dInputGuarantor = {
  i18nOptions: {
    namespaces: ['common'],
  },
  mixins: [dInputComponentMixin, dInputAutocompleteMixin],
  props: {
    label: { type: String, default: 'Garant' },
    kind: { type: String, default: 'undefined' },
  },
  computed: {
    cssClassName() {
      // if multiple instances are rendered in the same view there needs to be a unique class to attach to
      return `d-input-guarantor-${this._uid}`
    },
  },
  methods: {
    searchCallback(term) {
      return guarantorService.search(this.kind, term, {
        guarantor_id: this.model,
      })
    },
  },
}
export default dInputGuarantor
</script>
