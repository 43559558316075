export function leftByToothPosition(position) {
  if (isQuadrantFlipped(position)) {
    return 'mesial'
  } else {
    return 'distal'
  }
}

export function rightByToothPosition(position) {
  if (!isQuadrantFlipped(position)) {
    return 'mesial'
  } else {
    return 'distal'
  }
}
// Yet another hack, we need the 'c' for the tooth-schema to render the finding in the crown area
// but we don't display the 'c' as a part of the label
export function toothAreaLabel(toothSelector) {
  return toothSelector.replace(/c/, '').replace(/  +/g, ' ')
}

export function isQuadrantFlipped(position) {
  const quadrant = position.toString()[0]
  if (quadrant === '1' || quadrant === '4') {
    return false
  } else {
    return true
  }
}

export function isChildTeeth(position) {
  const quadrantNumber = position.toString()[0]

  return quadrantNumber > 4
}

export function quadrantForPosition(position) {
  let quadrantNumber = position.toString()[0]

  if (isChildTeeth(position)) {
    quadrantNumber = quadrantNumber - 4
  }

  return 'q' + quadrantNumber
}

export function isMaxilla(position) {
  const quadrant = quadrantForPosition(position)

  return ['q1', 'q2', 'q5', 'q6'].includes(quadrant)
}

export function isMandibula(position) {
  const quadrant = quadrantForPosition(position)

  return ['q3', 'q4', 'q7', 'q8'].includes(quadrant)
}

export function isSinister(position) {
  const quadrant = quadrantForPosition(position)

  return ['q2', 'q3', 'q6', 'q7'].includes(quadrant)
}

export function isDexter(position) {
  const quadrant = quadrantForPosition(position)

  return ['q1', 'q4', 'q5', 'q8'].includes(quadrant)
}

export function isIncisor(position) {
  return parseInt(position.toString()[1]) <= 2
}

export function isCanine(position) {
  return position.toString()[1] === '3'
}

export function maxillaOrMandibula(position) {
  const quad = position.toString()[0]
  if (quad === '1' || quad === '2') {
    return 'max'
  } else {
    return 'mand'
  }
}

export function hasAgenesis({ findingCodes }) {
  if (!findingCodes.includes('agenesis')) return false
  if (!findingCodes.includes('implant')) return true

  const implantIndex = findingCodes.indexOf('implant')
  const agenesisIndex = findingCodes.indexOf('agenesis')
  return agenesisIndex < implantIndex
}

export function hasMissingTooth({ findingCodes }) {
  if (!findingCodes.includes('missing-tooth')) return false
  if (!findingCodes.includes('implant')) return true

  const implantIndex = findingCodes.indexOf('implant')
  const agenesisIndex = findingCodes.indexOf('missing-tooth')
  return agenesisIndex < implantIndex
}
