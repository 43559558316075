
<v-app>
  <div class="full-height d-flex flex-column">
    <slot />
    <v-dialog
      v-for="(dialog, index) in dialogs"
      :key="dialog.id"
      :value="dialogVisible(index)"
      persistent
      scrollable
      :width="dialog.width"
      overlay-opacity="0.86"
      :content-class="dialog.contentClass"
      @click:outside="dialog.outsideClickable && dialog.cancel()"
      @keydown.esc="dialog.cancel"
    >
      <component
        :is="dialog.component"
        v-bind="dialog.props"
        @close="dialog.close"
        @cancel="dialog.cancel"
        @closable="dialog.closable = $event"
        @outsideClickable="dialog.outsideClickable = $event"
      />
    </v-dialog>
    <template v-if="isMiniApp">
      <mini-app
        :is-open="isMiniApp"
        :title="currentMiniApp.name"
        :max-content-width="currentMiniApp.width"
        :custom-header="currentMiniApp.customHeader"
        @close="closeMiniApp"
      >
        <component
          :is="currentMiniApp.component"
          v-bind="launchedComponentProps"
        />
      </mini-app>
    </template>
    <v-snackbar
      v-model="snackbar.isOpen"
      :timeout="snackbar.timeout"
      bottom
      right
    >
      <div class="d-flex align-center justify-space-between">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <v-progress-circular
          v-if="snackbar.showUntil"
          indeterminate
          color="primary"
        />
        <span v-html="snackbar.text" />
        <span>
          <v-btn
            v-if="snackbar.primaryButton"
            dark
            text
            class="ml-6"
            @click="snackbarClick"
          >
            {{ $t(snackbar.primaryButton) }}
          </v-btn>
          <v-btn fab small dark text @click="hideSnackBar">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </span>
      </div>
    </v-snackbar>
  </div>
</v-app>
