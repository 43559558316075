
<d-form
  :form-data="formData"
  :validation="validationsOfChecked"
  :errors="errors"
  @valid-submit="submit"
>
  <d-modal>
    <d-modal-title :title="title" @close="$emit('cancel')" />

    <d-modal-content>
      <v-row v-for="(attribute, name) in attributes" :key="name" no-gutters>
        <v-col cols="4">
          <d-input-checkbox
            v-model="checked[name]"
            :label="attribute.i18nKey"
          />
        </v-col>

        <v-col cols="8">
          <slot :name="name" v-bind="attrs(name)" />
        </v-col>
      </v-row>
    </d-modal-content>

    <d-modal-actions>
      <d-btn role="secondary" label="cancel" @click="$emit('cancel')" />
      <d-btn
        type="submit"
        :disabled="!anyChecked"
        label="save"
        role="primary"
      />
    </d-modal-actions>
  </d-modal>
</d-form>
