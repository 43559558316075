
<div class="navigation__flyout" data-flyout="profile">
  <ul class="navigation__list">
    <li class="navigation__item navigation__item-has-children">
      <ul class="navigation__inner">
        <navigation-link
          :can-access="canAccess('profiles')"
          label="side_nav.news"
          url="profile/news"
        />
        <navigation-link
          :can-access="true"
          label="side_nav.help"
          :url="zendeskUrl()"
          :rel="'noopener'"
        />
        <navigation-link
          :can-access="canAccess('profiles')"
          label="side_nav.edit_profile"
          url="profile/edit"
        />
        <navigation-link
          :can-access="canAccess('profiles')"
          label="side_nav.logout"
          url="users/sign_out"
          data-method="delete"
          rel="nofollow"
        />
      </ul>
    </li>
  </ul>
</div>
