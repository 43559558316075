class Navigation {
  constructor() {
    this.selector = (el) => document.querySelector(el)
    this.selectors = (el) => document.querySelectorAll(el)
    this.addListeners()
  }

  addListeners() {
    document.addEventListener('turbolinks:load', () => this.initHandler())
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') this.closeNavigation()
    })
  }

  initHandler() {
    this.navOverlayAElement = this.selector('.navigation-overlay')
    this.navFlyoutElements = this.selectors('.js-flyout')
    this.navFlyoutListItems = this.selectors('[data-flyout]')

    if (this.navOverlayAElement && this.navFlyoutElements) {
      this.navFlyoutElements.forEach((el) =>
        el.addEventListener('click', (e) => this.clickHandler(e))
      )
      this.navOverlayAElement.addEventListener('click', () =>
        this.closeNavigation()
      )
      this.checkURLHandler()
      this.scrollIntoViewHandler()
    }
  }

  isElementInViewport(el) {
    const bounding = el.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  scrollIntoViewHandler() {
    const activeElements = this.selectors('.navigation__link--active')
    activeElements &&
      activeElements.forEach((el) => {
        if (!this.isElementInViewport(el)) el.scrollIntoView()
      })
  }

  clickHandler(e) {
    e.preventDefault()
    this.navOverlayActive = 'navigation-overlay--active'
    this.navFlyoutActive = 'navigation__flyout--active'
    this.navFlyoutActiveWithOverlay = 'navigation__flyout--active-with-overlay'
    this.navFlyoutLinkActive = 'navigation__link--opened'
    this.clickedFlyoutLink = this.selector(
      `[data-flyout=${e.currentTarget.id}]`
    )
    if (this.clickedFlyoutLink.classList.contains(this.navFlyoutActive)) {
      this.removeActiveClassFromFlyout()
      this.navOverlayAElement.classList.remove(this.navOverlayActive)
      e.currentTarget.classList.remove(this.navFlyoutLinkActive)
    } else {
      this.removeActiveClassFromFlyout()
      this.clickedFlyoutLink.classList.add(
        this.navFlyoutActive,
        this.navFlyoutActiveWithOverlay
      )
      this.navOverlayAElement.classList.add(this.navOverlayActive)
      e.currentTarget.classList.add(this.navFlyoutLinkActive)
    }
  }

  removeActiveClassFromFlyout() {
    this.flyoutElements = this.selectors('[data-flyout]')
    this.flyoutElements.forEach((el) =>
      el.classList.remove(this.navFlyoutActive, this.navFlyoutActiveWithOverlay)
    )
    this.navFlyoutElements.forEach((el) =>
      el.classList.remove(this.navFlyoutLinkActive)
    )
  }

  closeNavigation() {
    this.removeActiveClassFromFlyout()
    if (this.navOverlayAElement) {
      this.navOverlayAElement.classList.remove(this.navOverlayActive)
      this.checkURLHandler()
    }
  }

  checkURLHandler() {
    this.operationLinks = this.selector('[data-flyout="operation"]')
    this.settingsLinks = this.selector('[data-flyout="settings"]')
    this.layoutElement = this.selector('.ui-layout')
    const operationLinksList = this.selector('[data-flyout="operation"]')
      .querySelector('.navigation__list')
      .querySelectorAll('.navigation__link')
    const settingsLinksList = this.selector('[data-flyout="settings"]')
      .querySelector('.navigation__list')
      .querySelectorAll('.navigation__link')

    if (this.isPageExistInFlyout(operationLinksList)) {
      this.layoutElement.classList.add('ui-layout--narrow')
      this.operationLinks.classList.add('navigation__flyout--active')
      this.selector('#operation').classList.add('navigation__link--disabled')
    } else if (this.isPageExistInFlyout(settingsLinksList)) {
      this.layoutElement.classList.add('ui-layout--narrow')
      this.settingsLinks.classList.add('navigation__flyout--active')
      this.selector('#settings').classList.add('navigation__link--disabled')
    }

    if (
      /^\/patients\/.*/.test(location.pathname) &&
      location.pathname !== '/patients/new' &&
      !location.pathname.includes('without_recall') &&
      !location.pathname.includes('export')
    ) {
      this.selectors('.navigation__flyout').forEach((el) =>
        el.classList.remove('navigation__flyout--active')
      )

      this.selectors('.js-flyout').forEach((el) =>
        el.classList.remove('navigation__link--disabled')
      )

      const locationQuery = window.location.search
      const getCurrentURL = locationQuery
        ? `${window.location.pathname}${locationQuery}`
        : window.location.pathname
      const currentPatientId = getCurrentURL.split('/').filter((el) => el)[1]
      const recentPatientId = localStorage.getItem('recentPatientIds')
      const hasPatientId = recentPatientId?.includes(currentPatientId)

      if (hasPatientId) {
        const currentPatientObject = {
          pathname: getCurrentURL,
          id: currentPatientId,
        }
        localStorage.setItem(
          `recentPatientIdObject-${currentPatientId}`,
          JSON.stringify(currentPatientObject)
        )
      }
    }
  }

  isPageExistInFlyout(linksList) {
    if (linksList.length) {
      return [...linksList].some((el) => {
        return (
          window.location.pathname.startsWith(el.pathname) &&
          !window.location.pathname.match(/\/patients\/[1-9]+/)
        )
      })
    }
  }
}

const initNav = new Navigation()

export default initNav
