import { ref, watch } from '@vue/composition-api'
import type { PageInfo } from '@/graphql/generated/graphql'
import pickBy from 'lodash/pickBy'

type PaginationEvent = {
  pageInfo: PageInfo
  itemsPerPage: number
  action: 'start' | 'end' | 'prev' | 'next' | 'refresh'
}

type Pagination = {
  after?: string
  first?: number
  before?: string
  last?: number
}

const itemsPerPageDefault = 25
export const defaultItemsPerPageOptions = () => [itemsPerPageDefault, 50, 100]

// Omits null and undefined values, so that we don't end up with ?after=null
const paramsForUrl = (params: Pagination) => {
  return pickBy(params, (value) => value !== null && value !== undefined)
}

const extractPaginationFromUrl = (
  queryParams: URLSearchParams,
  itemsPerPageOptions: number[]
): Pagination => {
  const first = queryParams.get('first')
    ? validItemsPerPage(queryParams.get('first'), itemsPerPageOptions)
    : null

  const last = queryParams.get('last')
    ? validItemsPerPage(queryParams.get('last'), itemsPerPageOptions)
    : null

  return paramsForUrl({
    first,
    after: queryParams.get('after') || null,
    last,
    before: queryParams.get('before') || null,
  }) as Pagination
}

const validItemsPerPage = (itemsPerPage: string, options: number[]) => {
  const itemsPerPageInt = parseInt(itemsPerPage)
  return options.includes(itemsPerPageInt)
    ? itemsPerPageInt
    : itemsPerPageDefault
}

/**
 * The main composable to be used together with the `d-data-table` component
 * @param options.syncUrl If true, the pagination params will be synchronized with the URL
 * @example```
 * <script setup>
 * import { usePagination } from '@/libs/use-pagination'
 * const { pagination, itemsPerPage, didPaginationChange, reset } = usePagination()
 * </script>
 *
 * <template>
 * <d-data-table
 *  class="mt-6"
 *  disable-sort
 *  :headers="headers"
 *  :items="result?.edges?.map((e) => e.node)"
 *  :loading="loading"
 *  :items-total="result?.totalCount"
 *  :items-per-page="itemsPerPage"
 *  :page-info="result?.pageInfo"
 *  @paginate="didPaginationChange"
 * >
 * </template>
 * ```
 */
export const usePagination = (options: { syncUrl?: boolean }) => {
  const syncUrl = options.syncUrl || false
  // NOTE: this composable could be extended to take in the
  // itemsPerPage options as a parameter, but for now we'll just
  // enforce the default options for consistency

  /**
   * The options that are available for the user to select
   */
  const itemsPerPageOptions = defaultItemsPerPageOptions()

  /*
   * Pick up the pagination params from the URL
   */
  const paginationFromUrl = extractPaginationFromUrl(
    new URLSearchParams(window.location.search),
    itemsPerPageOptions
  )

  // The default pagination params, should the URL not have any
  const paginationDefault = {
    first: itemsPerPageDefault,
    after: null,
  } as Pagination

  // The number of items per page, this is overrideable
  // by the url params
  const itemsPerPage = ref<number>(
    paginationFromUrl.first || paginationFromUrl.last || itemsPerPageDefault
  )

  /**
   * These are the pagination args that are passed to the GraphQL backend
   * They are also used to generate the URL params
   */
  const pagination = ref<Pagination>(
    syncUrl && Object.keys(paginationFromUrl).length > 0
      ? paginationFromUrl
      : paginationDefault
  )

  // This watcher synchronizes the pagination params with the URL
  // The synchronization is one way, from the pagination params to the URL
  // If url params are changed by the user, the page is reloaded anyway
  if (syncUrl) {
    watch(pagination, (newPagination: Pagination) => {
      // TODO: no idea how to make this typesafe
      const urlParams = new URLSearchParams(paramsForUrl(newPagination))
      window.history.pushState({}, '', `?${urlParams.toString()}`)
    })
  }

  /**
   * Call this to restart pagination from the beginning.
   * This keeps the itemsPerPage value, but resets the pagination,
   * which is useful when the user changes e.g. filters
   */
  const resetPagination = () => {
    pagination.value = { first: itemsPerPage.value, after: null }
  }

  /**
   * This is the handler for the `paginate` event emitted by the `d-data-table` component.
   * It updates the `pagination` and `itemsPerPage` params based on the user interaction with the table
   * @param {PaginationEvent} paginate The event payload
   */
  const didPaginationChange = (paginate: PaginationEvent) => {
    itemsPerPage.value = paginate.itemsPerPage

    switch (paginate.action) {
      case 'start':
        pagination.value = { first: itemsPerPage.value, after: null }
        break

      case 'end':
        pagination.value = { last: itemsPerPage.value, before: null }
        break

      case 'prev':
        pagination.value = {
          last: itemsPerPage.value,
          before: paginate.pageInfo.startCursor,
        }
        break

      case 'next':
        pagination.value = {
          first: itemsPerPage.value,
          after: paginate.pageInfo.endCursor,
        }
        break

      case 'refresh':
        if ('after' in pagination.value || 'first' in pagination.value) {
          pagination.value = {
            first: itemsPerPage.value,
            after: pagination.value.after,
          }
        } else if ('before' in pagination.value || 'last' in pagination.value) {
          pagination.value = {
            last: itemsPerPage.value,
            before: pagination.value.before,
          }
        }
        break
    }
  }

  return {
    itemsPerPage,
    itemsPerPageOptions,
    didPaginationChange,
    pagination,
    resetPagination,
  }
}
