<template>
  <v-form novalidate @submit.prevent="submit">
    <slot v-bind="slotProps" />
  </v-form>
</template>
<script>
import { validationMixin } from 'vuelidate'
import get from 'lodash/get'
import set from 'lodash/set'

export default {
  mixins: [validationMixin],
  provide() {
    return {
      dForm: {
        registerInput: (fieldName, element) => {
          this.inputs[fieldName] = element
        },
        unregisterInput: (key) => {
          delete this.inputs[key]
        },
        registerValidations: (fieldName, validations) => {
          set(this.allValidations, fieldName, validations)
          this.$set(this, 'allValidations', { ...this.allValidations })
        },
        field: this.field,
        readonlyFields: this.readonlyFields,
        additionalFieldErrors: this.fieldErrors,
        triggerChange: () => this.triggerChange(),
        submit: () => this.submit(),
        touch: () => this.touch(),
        formDisabled: () => this.disabled || this.loading,
      },
    }
  },
  props: {
    validation: {
      type: Object,
      default: () => ({}),
      validator: (value) => {
        return Object.values(value).every(
          (validation) => typeof validation === 'object'
        )
      },
    },
    formData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonlyFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    Object.keys(this.formData).forEach((formDataKey) => {
      if (!Object.prototype.hasOwnProperty.call(this.validation, formDataKey)) {
        this.validation[formDataKey] = {}
      }
    })
    return {
      form: this.allValidations,
    }
  },
  data() {
    return {
      inputs: {},
      form: {},
      localErrors: {},
      allValidations: this.validation,
    }
  },
  computed: {
    invalid() {
      return this.vuelidate.$invalid
    },
    valid() {
      return !this.invalid
    },
    touched() {
      return this.vuelidate.$anyDirty
    },
    slotProps() {
      const { valid, invalid, form, touched } = this
      return {
        invalid,
        valid,
        touched,
        form,
        submit: () => this.submit(),
      }
    },
    vuelidate() {
      return this.$v
    },
    fieldErrors() {
      return (field) => {
        return (this.errors && this.errors[field]) || []
      }
    },
    field() {
      return (name) => get(this.vuelidate.form, name)
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!value) {
          return
        }
        this.$set(this, 'form', { ...this.form, ...value })
      },
    },
    validation: {
      handler(value) {
        this.allValidations = value
      },
    },
  },
  methods: {
    submit() {
      this.vuelidate.$touch()

      this.$emit('submit', this.form)

      if (!this.invalid) {
        this.$emit('valid-submit', this.form)
      }
    },
    focus(fieldName) {
      this.inputs[fieldName].focus()
    },
    touch() {
      this.vuelidate.$touch()
    },
    reset() {
      this.vuelidate.$reset()
    },
    triggerChange() {
      const updatedForm = { ...this.form }
      this.$emit('update:formData', updatedForm)
      this.$emit('change', updatedForm)
    },
  },
}
</script>
