import { destroy, get, post, put } from '@/libs/axiosWrapper'
import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'

export default class RestEntity {
  apiBaseUrl = '/json'
  entityName = '' // Should be overridden by extending class.
  jsonPath = false
  singleResource = false // eg. /json/profile/update

  constructor(entityOwnerPath = null, entityName = '') {
    this.entityOwnerPath = entityOwnerPath
    this.entityName = entityName
  }

  get collectionName() {
    if (this.singleResource) {
      return this.entityName
    }

    return this.entityName + 's'
  }

  /**
   * overwrite this in extending class if you need to filter attributes
   */
  serialize(entity) {
    if (this.serializer === 'convert_case') {
      return this.serializeCaseConversion(entity)
    } else {
      return entity
    }
  }

  /**
   * overwrite this in extending class if you need to filter attributes
   */
  deserialize(response) {
    if (this.serializer === 'convert_case') {
      return this.deserializeCaseConversion(response)
    } else {
      return response
    }
  }

  serializeCaseConversion(entity) {
    return mapKeys(entity, (_value, key) => snakeCase(key))
  }

  deserializeCaseConversion(response) {
    return response.then((body) => {
      const collectionRoot = body[this.collectionName]
      const entityRoot = body[this.entityName]

      if (collectionRoot) {
        const entities = collectionRoot.map((entity) => {
          return mapKeys(entity, (_value, key) => camelCase(key))
        })

        return { [this.collectionName]: entities }
      } else if (entityRoot) {
        const entity = mapKeys(entityRoot, (_value, key) => camelCase(key))

        return { [this.entityName]: entity }
      } else {
        const entities = body.map((entity) => {
          return mapKeys(entity, (_value, key) => camelCase(key))
        })

        return entities
      }
    })
  }

  fetch(entityId, params = {}) {
    return this.deserialize(get(this.entityPath(entityId), { params }))
  }

  fetchAll(params = {}) {
    return this.deserialize(get(this.entityPath(), { params }))
  }

  save(entity, config) {
    const body = { [this.entityName]: this.serialize(entity) }
    if (entity.id && !this.singleResource) {
      return this.deserialize(put(this.entityPath(entity.id), body, config))
    } else if (this.singleResource) {
      return this.deserialize(put(this.entityPath(), body, config))
    }
    return this.deserialize(post(this.entityPath(), body, config))
  }

  destroy(entityId, config) {
    return destroy(this.entityPath(entityId), config)
  }

  entityPath(additionalPath = []) {
    additionalPath = Array.isArray(additionalPath)
      ? additionalPath
      : [additionalPath]

    const path = [
      this.apiBaseUrl,
      this.entityOwnerPath,
      this.collectionName,
      ...additionalPath,
    ]
      .filter((item) => item)
      .join('/')

    return this.jsonPath ? `${path}.json` : path
  }

  get(path, config) {
    return get(this.entityPath(path), config)
  }

  post(path, data, config) {
    return post(this.entityPath(path), data, config)
  }

  put(path, data, config) {
    return put(this.entityPath(path), data, config)
  }
}
