<template>
  <v-file-input
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    clearable
    v-on="defaultHandlers"
    @change="handleFileEncoding"
  />
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
  methods: {
    handleFileEncoding(file) {
      const self = this
      if (file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener(
          'load',
          () => {
            self.$emit('added-file', reader.result)
          },
          false
        )
      } else {
        self.$emit('removed-file')
      }
    },
  },
}
</script>
