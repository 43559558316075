<template>
  <li v-if="canAccess" class="navigation__item navigation__item--inner">
    <a
      :href="pageUrl()"
      :class="[
        'navigation__link',
        { 'navigation__link--active': isLinkActive() },
      ]"
      :data-method="dataMethod"
      :rel="rel"
      :target="isExternal() ? '_blank' : null"
    >
      {{ $t(label) }}
    </a>
  </li>
</template>

<script>
export default {
  i18nOptions: {
    namespaces: ['application'],
  },
  props: {
    patientId: { type: Number, default: null },
    canAccess: { type: Boolean, default: false },
    label: { type: String, default: null },
    url: { type: String, default: null },
    activeRule: { type: [String, RegExp], default: null },
    dataMethod: { type: String, default: null },
    rel: { type: String, default: null },
  },
  methods: {
    pageUrl() {
      if (this.isExternal()) {
        return this.url
      }

      const section = this.patientId ? `/patients/${this.patientId}` : ''
      const param = this.url ? `/${this.url}` : ''
      return `${section}${param}`
    },
    isLinkActive() {
      const requestPath = window.location.pathname.split('?')[0]

      if (this.url === '') {
        return `/patients/${this.patientId}` === requestPath
      }

      return (
        requestPath.match(this.url) ||
        (this.activeRule instanceof RegExp &&
          requestPath.match(this.activeRule))
      )
    },
    isExternal() {
      return this.url.startsWith('http')
    },
  },
}
</script>
