import RestEntity from './rest-entity'
import pick from 'lodash/pick'

export const emptySection = Object.freeze({
  id: -1, // null is already in use for sections that should newly be created
  name: '',
})

export const businessObjectTypes = {
  NTARIFF: 'Ntariff',
  TARIFF: 'Tariff',
  LAB: 'Lab',
  SAMPLE: 'Sample',
  ARTICLE: 'Article',
  DISCOUNT: 'Discount',
}

export const guarantorKinds = {
  PRIVATE: 'private',
  KVG: 'kvg',
  UVG: 'uvg',
  IVG: 'ivg',
  MVG: 'mvg',
  SCHOOL: 'school',
  MISC: 'misc',
  SOCIAL: 'social',
}

export const tariffTypes = {
  DENTOTAR: 'Ntariff',
  TARIFF94: 'Tariff',
}

export class Position extends RestEntity {
  businessObjectTypes = businessObjectTypes
  guarantorKinds = guarantorKinds
  tariffTypes = tariffTypes

  fetchPositions() {
    return this.get('').then((result) => result[this.collectionName])
  }

  savePosition(position) {
    const payload = this.payloadFromPosition(position)
    if (position.id) {
      return this.put([position.id], payload).then(
        (result) => result[this.entityName]
      )
    }
    return this.post('', payload).then((result) => result[this.entityName])
  }

  bulkUpdatePositions(positionIds, position) {
    return this.post(['bulk_update'], {
      [this.entityName]: position,
      ids: positionIds,
    })
  }

  bulkDestroyPositions(positionIds) {
    return this.destroy(['bulk_destroy'], {
      data: {
        ids: positionIds,
      },
    })
  }

  evaluatePosition(position = { id: null }) {
    const payload = this.payloadFromPosition(position)
    return this.post(['evaluate'], payload).then(
      (result) => result[this.entityName]
    )
  }

  payloadFromPosition(position) {
    return {
      [this.entityName]: {
        ...position,
        sub_positions:
          position.sub_positions &&
          position.sub_positions.map((pos) => ({
            ...pos,
            physician_id: position.physician_id,
            location_id: position.location_id,
          })),
      },
    }
  }

  sortPositions(positionsAndSections) {
    return this.post('/sort', {
      positions_and_sections: positionsAndSections.map((item) =>
        pick(item, ['id', 'section_id'])
      ),
    })
  }

  tariffTypeHasSubPositions(type) {
    return type === this.tariffTypes.DENTOTAR
  }

  emptyPosition(position) {
    return {
      business_object_poly_id: null,
      physician_id: null,
      at: new Date().toISOString(),
      comment: null,
      tooth: '',
      quantity: 1,
      rate: null,
      unit_price: null,
      percentage_discount: null,
      guarantor_kind: this.guarantorKinds.KVG,
      business_object_type: this.tariffTypes.DENTOTAR,
      sub_positions: [],
      total_price: null,
      ...position,
    }
  }

  emptySubPosition(location_id = null) {
    return {
      business_object_poly_id: null,
      comment: '',
      quantity: 1,
      unit_price: null,
      rate: null,
      total_price: null,
      location_id: location_id,
    }
  }

  undiscountableGuarantorKinds() {
    return [guarantorKinds.IVG, guarantorKinds.MVG, guarantorKinds.UVG]
  }
}
