
<!-- TODO: title should not be translated -->
<d-section :title="title">
  <d-nested-table
    :headers="headers"
    enable-loading
    :loading="isLoading"
    :items="items"
    :no-data-text="$t('empty_table')"
  >
    <template #item.code="{ item }">
      <span class="text-no-wrap">
        {{ item.code }}
      </span>
    </template>
  </d-nested-table>
</d-section>
