import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify'

const ANCHOR = '#00B380'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#66F1B8',
        secondary: ANCHOR,
        accent: ANCHOR,
        success: '#00B380',
        anchor: ANCHOR,
        error: '#F44336',
        warning: '#FF8833',
        alert: '#FEDB82',
        'grey-02': '#FAFAFA',
        'grey-05': '#F2F2F2',
        'grey-10': '#E6E6E6',
        'grey-20': '#CCCCCC',
        'grey-30': '#b0b0b0',
        'grey-40': '#999999',
        'grey-60': '#666666',
        'grey-80': '#333333',
        'state-green': '#4CDDA0',
        'state-red': '#FC646D',
        'state-blue': '#61C0FD',
        'state-gray': '#C5CCD3',
        'state-yellow': '#FECB4D',
      },
    },
  },
})
