
<v-autocomplete
  ref="autocomplete"
  v-bind="{ ...defaultAttrs, ...$attrs }"
  :search-input.sync="search"
  autocomplete="off"
  v-on="$listeners"
>
  <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

  <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
    <slot :name="slotName" v-bind="slotData" />
  </template>

  <template
    v-if="allowNewItem && search && !$refs.autocomplete.filteredItems.length"
    #append-item
  >
    <div
      class="px-4 v-list-item v-list-item--link theme--light"
      @click="newItemCreationCallback"
    >
      <div class="v-list-item__content">
        <span class="v-list-item__title new-item">
          {{ newItemLabel }}
        </span>
      </div>
    </div>
  </template>
</v-autocomplete>
