
<div class="d-flex align-center mb-6">
  <h1 class="main-title text-h4 d-flex align-center">
    <slot>
      {{ parent.$t(props.title) }}
    </slot>
  </h1>
  <div class="actions d-flex align-center">
    <slot name="actions" />
  </div>
</div>
