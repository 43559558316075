<template>
  <li class="navigation__item navigation__item-has-children">
    <transition-group tag="ul" class="navigation__inner" name="trs-list">
      <li
        v-for="patient in patients"
        :key="patient.id"
        class="navigation__item navigation__item--profile"
        :class="{ 'navigation__item--profile--active': isActive(patient.id) }"
      >
        <a :href="getActiveURLIfExist(patient.id)" class="navigation__link">
          {{ patient.initials }}
        </a>
      </li>
    </transition-group>
  </li>
</template>

<script>
import { gql } from '@apollo/client/core'
import { query } from '@/libs/graphql'
import patientIdParam from './services/patient-id-param'

export default {
  data() {
    return {
      patientId: undefined,
      patientIds: [],
      patientCache: {},
    }
  },

  computed: {
    patients() {
      return this.patientIds.map((id) => this.patientCache[id]).filter((e) => e)
    },
  },

  created() {
    document.addEventListener('turbolinks:load', this.refresh)
  },

  mounted() {
    // Fire that event once the recentPatientsId in localstorage updated
    // when deleting archived patients
    this.$eventBus.$on('refreshRecentPatientIds', () => this.refresh())
  },

  beforeDestroy() {
    document.removeEventListener('turbolinks:load', this.refresh)
  },

  methods: {
    refresh() {
      this.patientIds =
        JSON.parse(window.localStorage.getItem('recentPatientIds')) || []

      const patientIDFromParam = patientIdParam()
      if (patientIDFromParam) {
        this.recordPatient(patientIDFromParam)
      } else {
        this.patientId = null
      }

      this.patientIds.forEach((id) => this.fetchPatient(id))
    },

    isActive(id) {
      return this.patientId === id
    },

    persistPatientIds() {
      window.localStorage.setItem(
        'recentPatientIds',
        JSON.stringify(this.patientIds)
      )
    },

    recordPatient(id) {
      this.patientId = id
      if (!this.patientIds.includes(id)) {
        this.patientIds = [id, ...this.patientIds.slice(0, 2)]
        this.persistPatientIds()
      }
    },

    async fetchPatient(id) {
      const response = await query({
        query: gql`
          query patient($id: ID!) {
            patient(id: $id) {
              id
              initials
              displayName
              pictureUrl(size: "thumb")
            }
          }
        `,
        variables: {
          id,
        },
      })

      if (response.errors) {
        // If we can't fetch the patient, we also drop it from the list
        this.patientIds = this.patientIds.filter(
          (patientId) => patientId !== id
        )
        this.persistPatientIds()
        localStorage.removeItem(`recentPatientIdObject-${id}`)

        return
      }

      const {
        data: {
          patient: { initials, displayName, pictureUrl },
        },
      } = response

      this.patientCache = {
        ...this.patientCache,
        [id]: { id, initials, displayName, pictureUrl },
      }
    },
    getActiveURLIfExist(id) {
      const currentPatientObj = JSON.parse(
        localStorage.getItem(`recentPatientIdObject-${id}`)
      )
      return id && currentPatientObj
        ? currentPatientObj.pathname
        : `/patients/${id}`
    },
  },
}
</script>

<style lang="scss">
.navigation__list--profiles {
  // Vars
  /*
  * 48px width + 8px margin = 56px * 4 item = 224px
  * 3 is visible in the UI and 1 is just for spacing
  */
  --list-height: 224px;
  --box-shadow-outline: 0 0 0 4px;

  // Support

  // Module
  & {
    .navigation__inner {
      height: var(--list-height);
    }

    .navigation__item-has-children {
      overflow: visible;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .navigation__item--profile {
      border: 1px solid var(--c-nav-hover);
      background-color: inherit;
    }
  }

  // Facets
  & {
    .navigation__item--profile--active {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
    }
  }

  // States
  & {
    .navigation__item--profile:hover {
      background-color: var(--c-nav-hover);
      border-color: var(--c-nav-hover);
    }

    .navigation__item--profile--active:hover {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
    }
  }
}

// Transitions
.navigation__item--profile {
  transition: transform 0.4s, opacity 0.4s;
  display: inline-block;
}
.trs-list-enter,
.trs-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.trs-list-leave-active {
  position: absolute;
}
</style>
