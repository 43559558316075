<template>
  <fragment>
    <li class="navigation__item navigation__item-has-children">
      <ul class="navigation__inner">
        <navigation-link
          :can-access="canInspectPatient"
          label="patient.overview"
          url=""
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('dated_positions')"
          label="patient.services"
          url="dated_positions"
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('clinical_records')"
          label="patient.clinical_records"
          url="clinical_records"
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('prescriptions')"
          label="patient.prescriptions"
          url="prescriptions"
          :patient-id="patientId"
        />
      </ul>
    </li>
    <li
      v-if="canAccessSecondMenu()"
      class="navigation__item navigation__item-has-children"
    >
      <ul class="navigation__inner">
        <navigation-link
          :can-access="canAccess('journal_positions')"
          label="patient.journal_position"
          url="journal_positions"
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('invoices')"
          label="patient.invoices"
          url="invoices"
          :active-rule="/^\/patients\/.*\/pay_slips\/new/"
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('bids')"
          label="patient.bids"
          url="bids"
          :patient-id="patientId"
        />
        <navigation-link
          v-if="$featureFlag.feature_flags_enable_germany"
          :can-access="canAccess('care_plans')"
          label="patient.careplans"
          url="care_plans"
          :patient-id="patientId"
        />
        <navigation-link
          :can-access="canAccess('patient_recalls')"
          label="patient.recalls"
          url="recalls"
          :patient-id="patientId"
        />
      </ul>
    </li>
    <li
      v-if="canAccess('media_assets')"
      class="navigation__item navigation__item-has-children"
    >
      <ul class="navigation__inner">
        <navigation-link
          :can-access="canAccess('media_assets')"
          label="patient.media_assets"
          url="media_assets"
          :patient-id="patientId"
        />
      </ul>
    </li>
  </fragment>
</template>

<script>
import NavigationLink from './components/navigation-link.vue'

export default {
  components: { NavigationLink },
  props: {
    patient: { type: Object, required: true },
    userPermissions: { type: Object, required: true },
  },
  computed: {
    patientId() {
      return this.patient.id
    },
    canInspectPatient() {
      return this.patient.info.permissions.canInspect
    },
  },
  methods: {
    canAccess(action) {
      return this.userPermissions[action] && this.canInspectPatient
    },
    canAccessSecondMenu() {
      return (
        this.canAccess('journal_positions') ||
        this.canAccess('invoices') ||
        this.canAccess('bids') ||
        this.canAccess('patient_recalls')
      )
    },
  },
}
</script>
