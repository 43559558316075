<template>
  <v-switch
    v-model="model"
    v-bind="defaultInputBindings"
    :color="color"
    v-on="defaultHandlers"
  />
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
  props: {
    color: { type: String, default: undefined },
  },
}
</script>
