import RestEntity from './rest-entity'

class Event extends RestEntity {
  entityName = 'event'

  transformEntityBeforeSave(event) {
    return {
      id: event.id || null,
      start: event.start.toString(),
      end: event.end.toString(),
      text: event.text,
      patient_id: event.patient_id || null,
      physician_id: event.physician_id,
      event_type_id: event.event_type_id,
      calendar_room_id: event.calendar_room_id || null, // vuetify_bug_1
      calendar_resource_ids: event.calendar_resource_ids,
      all_day: event.allDay,
      recurrence: event.recurrence,
      original_start: event.original_start || null,
      notify: event.notify || null,
      location_id: event.location_id,
    }
  }

  save(event, config) {
    return super.save(this.transformEntityBeforeSave(event), config)
  }

  async updateTeamMemberId(oldTeamMemberId, newTeamMemberId) {
    await this.post(`${oldTeamMemberId}/update_team_member_id`, {
      old_team_member_id: oldTeamMemberId,
      new_team_member_id: newTeamMemberId,
    })
  }

  async updateEventTypeOfTeamMember(teamMemberId, newEventTypeId) {
    await this.post(`${teamMemberId}/update_event_type_of_team_member`, {
      team_member_id: teamMemberId,
      new_event_type_id: newEventTypeId,
    })
  }

  async updateClientMissed(eventId, patient_missed) {
    await this.put(`${eventId}`, {
      patient_missed,
    })
  }
}

export default new Event()
