import moment from 'moment'
import { numberToCurrency, numberWithDelimiter } from './number-format'

export interface ViewHelpers {
  formatDate: (date: string | Date) => string
  formatDateTime: (dateTime: string | Date) => string
  formatTime: (time: string | Date) => string
  formatPrice: (
    price: number | string,
    currency?: 'CHF',
    locale?: string
  ) => string
  formatNumber: (number: number | string, decimals?: number) => string
}

export const viewHelpers: ViewHelpers = {
  formatDate(date) {
    return moment(date).format('L')
  },
  formatDateTime(dateTime) {
    return moment(dateTime).format('L, LT')
  },
  formatTime(time) {
    return moment(time).format('LT')
  },
  formatPrice(price, currency, locale) {
    return numberToCurrency(price, currency, locale)
  },
  formatNumber(number, decimals = 2) {
    return numberWithDelimiter(number, decimals)
  },
}
