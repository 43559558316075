
<div class="d-flex justify-space-between mini-app-header">
  <div class="mini-app-header__title">{{ $t(title) }}</div>
  <div class="d-flex align-center">
    <v-fade-transition>
      <span v-if="launcher.lastSaved" class="last-saved">{{
        formatTime(launcher.lastSaved)
      }}</span>
    </v-fade-transition>
    <component :is="customHeaderComponentName()" v-if="customHeader" />
    <d-btn v-else label="close" @click="launcher.close" />
  </div>
</div>
