<template>
  <div class="quick-command">
    <d-autocomplete
      ref="query"
      v-model="selected"
      v-click-outside="blur"
      :search-input.sync="query"
      :items="items"
      :menu-props="{ maxHeight: '400px', minWidth: '640px' }"
      :placeholder="$t('quicksearch')"
      :filled="false"
      item-text="label"
      item-value="id"
      append-icon=""
      hide-no-data
      height="90"
      background-color="#fff"
      class="quick-command__input"
      open-on-clear
      return-object
      no-filter
      clearable
      solo
      auto-select-first
      @keydown.esc="$emit('cancel')"
    >
      <template #prepend-inner>
        <d-icon symbol="icon-search" />
      </template>
      <template #item="{ item }">
        <template v-if="item.type === 'patient'">
          <div
            class="d-flex"
            :class="{
              'quick-command__disabled':
                item.isArchived || !item.permissions.canInspect,
            }"
          >
            <v-list-item-avatar class="picture">
              <img :src="item.profileThumbnailUrl" />
            </v-list-item-avatar>
            <div class="d-flex column">
              <div class="d-flex name">
                <span
                  v-html="highlight(item.fullNameWithNo, { normalize: true })"
                />
                <span
                  v-if="item.isArchived || !item.permissions.canInspect"
                  class="quick-command__archived"
                >
                  ({{
                    compact([
                      !item.permissions.canInspect &&
                        item.defaultLocation.shortname,
                      item.isArchived && $t('archived'),
                    ]).join(', ')
                  }})
                </span>
              </div>
              <div class="d-flex column">
                <div class="d-flex address">
                  <span v-html="highlight(item.fullAddress)"></span>
                </div>
                <div class="d-flex contact">
                  <span v-html="highlight(item.additionalInfo)"></span>
                </div>
              </div>
            </div>
            <div class="actions column">
              <div class="d-flex icon" @click="editPatient(item)">
                <v-icon>mdi-pencil</v-icon>
              </div>
            </div>
          </div>
        </template>
        <template v-if="item.type === 'global'">
          <v-list-item-avatar class="picture">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.label" />
          </v-list-item-content>
        </template>
      </template>
    </d-autocomplete>
  </div>
</template>

<script>
import { visit } from 'turbolinks'
import { highlight } from '@/libs/highlight'
import clickOutside from '../../libs/directives/click-outside'
import patientService from '@/services/patient'
import compact from 'lodash/compact'
import {
  createPatientCommands,
  createPatientsCommands,
  createGlobalCommands,
} from './commands'

export default {
  directives: {
    clickOutside,
  },
  i18nOptions: { namespaces: ['nav', 'commands'] },
  data() {
    return {
      patients: [],
      query: null,
      selectedCommand: null,
      patientId: null,
      patient: null,
      queryWords: [],
      compact,
    }
  },
  computed: {
    items() {
      let list = this.patients

      if (list.length) {
        list = [...list, { divider: true }]
      }

      const other = this.patientId
        ? createPatientCommands(this)
        : createGlobalCommands(this)

      return [...list, ...other]
    },
    selected: {
      get() {
        return this.selectedCommand
      },
      set(option) {
        if (option && option.command) {
          option.command(this)
        }

        this.selectedCommand = option
        this.query = null
        this.patients = []
      },
    },
  },
  watch: {
    query(newVal, oldVal) {
      newVal && newVal !== oldVal && newVal.length > 1 && this.runQuery(newVal)

      // When the query is emptied, we revert to the global commands
      // and the input is no longer patient specific
      if (!newVal) {
        this.selected = null
        this.patientId = null
      } else {
        this.queryWords = newVal.split(' ').filter((word) => word.length > 1)
      }
    },
  },
  created() {
    document.addEventListener('keydown', this.handleCmdK)
    document.addEventListener('turbolinks:load', this.refresh)
  },
  mounted() {
    this.focus()
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleCmdK)
    document.removeEventListener('turbolinks:load', this.refresh)
  },
  methods: {
    async runQuery(query) {
      /**
       * @param query - will be term param value to search for
       * @param true - will enable to include archived patients into the search results
       */
      const { patients } = await patientService.search(query, true)
      this.patients = createPatientsCommands(patients)
    },
    editPatient(patient) {
      patient.command = () => visit(`/patients/${patient.id}/edit`)
    },
    highlight(text, options = { normalize: false }) {
      return highlight(text, this.queryWords, {
        ...options,
        wrapperClass: 'highlighter--dark',
      })
    },
    async refresh() {
      // Need to extract this from the URL for now,
      // there's no reliable way to get this updated
      // from outside
      const urlMatches = window.location.href.match(/\/patients\/(\d+)/)

      if (urlMatches && urlMatches[1]) {
        this.patientId = urlMatches[1]

        // When a patientId is set (provided by the URL)
        // we are on a patient specific page and we
        // prepopulate the input with only the current patient
        try {
          const { patient } = await patientService.fetch(this.patientId)
          this.patients = createPatientsCommands([patient])

          // Don't go through this.select here to not clear
          // out the autocomplete options (here: our single patient)
          // because we don't want all the other side effects
          this.selectedCommand = this.patients[0]
        } catch (error) {
          // no access to this patient, most likely due to AppSetting.feature_flags_enable_patient_accessibility_restriction being set to true and not having enough permissions
          if (error.response.status != 403) {
            throw error
          }
        }
      } else {
        // Otherwise reset everything here
        this.patientId = null
        this.selected = null
      }
      this.$emit('cancel')
    },
    handleCmdK(event) {
      if (event.key === 'k' && (event.ctrlKey || event.metaKey)) {
        this.focus()
      }
    },
    focus() {
      this.$refs.query.focus()
    },
    blur() {
      this.$refs.query.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-command {
  // Vars
  --c-item-highlighted: #f3f3f3;
  --c-item-border: #000;
  --c-avatar: #e2e2e2;
  --box-size: 56px;
  --c-black: #000;

  // Support

  // Module
  & {
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding-top: 32px;

    .name {
      line-height: 20px;
      font-size: 16px;
    }

    .actions {
      display: flex;
      flex: 0 0 50px;
    }

    .icon {
      justify-content: center;
      align-self: center;
    }

    .address,
    .contact {
      line-height: 16px;
      font-size: 13px;
      color: var(--c-black);
      opacity: 0.7;
    }

    .d-flex {
      display: flex;
      position: relative;
      flex: 1 1 auto;
      max-width: 100%;
    }
    .column {
      flex-direction: column;
    }

    .quick-command__input {
      max-width: 740px !important;
      border-radius: var(--nav-item-border-radius) !important;
    }

    ::v-deep {
      .v-input__append-inner {
        width: var(--box-size);
        margin-right: 16px;
      }

      .v-input__prepend-inner {
        flex: 0 0 var(--box-size);
        width: var(--box-size);
        height: var(--box-size);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        margin-left: 16px;
        margin-right: 16px;
      }

      .v-list {
        padding: 0;
      }

      .v-list-item {
        padding: 16px;
      }

      .v-list-item__content {
        position: relative;
      }

      .v-list-item__title {
        font-size: 16px;
        line-height: 20px !important;
      }

      .v-select__slot > input {
        font-size: 22px;
        line-height: 24px;
      }

      .v-list-item--link {
        opacity: 1 !important;
        background-color: transparent;
        &:before {
          background-color: transparent;
        }
      }

      .v-autocomplete__content.v-menu__content {
        border-radius: var(--nav-item-border-radius) !important;
        box-shadow: none !important;
        margin-top: calc(var(--nav-padding));
      }

      .v-input__control > .v-input__slot {
        box-shadow: none !important;
        padding: 0 !important;
      }

      .v-list-item--highlighted {
        background-color: transparent;
        &:before {
          opacity: 1 !important;
          background-color: var(--c-item-highlighted);
        }
      }

      .d-flex {
        display: flex;
        position: relative;
      }
    }

    .picture {
      margin-right: 15px;
      margin-top: 0;
      margin-bottom: 0;
      height: 56px !important;
      width: 56px !important;
      align-items: baseline;
      border-radius: 8px;
      background-color: var(--c-avatar);

      img {
        object-fit: cover;
      }
    }
  }

  // State
  & {
    ::v-deep {
      .v-list-item:hover {
        transition: background-color 0.2s ease-in-out;
        background-color: var(--v-primary-base);
      }

      .v-select__slot > input::placeholder {
        opacity: 0.6;
      }
    }

    .quick-command__disabled {
      .v-avatar,
      .name,
      .address,
      .contact {
        opacity: 0.4;
      }
    }

    .quick-command__archived {
      margin-left: 4px;
    }
  }
}
</style>
