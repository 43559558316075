import cloneDeep from 'lodash/cloneDeep'

/**
 * Get selected surface areas
 * @param {object} state - The vuex state object
 * @return {Array} selected surface areas
 */
export const getSelectedSurfaceAreas = (state) => state.selectedSurfaceAreas

/**
 * Get filling assistant record form values
 * @param {object} state - The vuex state object
 * @return {Object} filling assistant record form values
 */
export const getTreatment = (state) => state.fillingAssistantRecord.form_values

export const mostUsedConfigLookupTable = (state) =>
  state.mostUsedConfigLookupTable

export const prefillButtonHasBeenClicked = (state) =>
  state.prefillButtonHasBeenClicked

/**
 * Get draining types
 * @param {object} state - The vuex state object
 * @return {String[]} draining types
 */
export const getDrainingTypes = (state) => state.drainingTypes

/**
 *
 * @param type The draining type for which the most used draining config should be returned
 * @returns Extracts the most used draining config given a particular draining type from the lookup table. Returns null in the following cases:
 * - Lookup table is null
 */
export const extractMostUsedDrainingConfig =
  (state, getters) =>
  (type = null) => {
    // lookup table may be null
    if (!getters.mostUsedConfigLookupTable) return null

    const snakeCaseType = type
      ? type
      : getters.mostUsedConfigLookupTable.draining.mostSelectedType

    const productOptionByType =
      getters.mostUsedConfigLookupTable.draining.productOptions.find(
        (option) => option.type === snakeCaseType
      )

    // productOptions may not contain selected type
    const products =
      productOptionByType?.products || getters.getTreatment.draining.products

    const config = {
      type: snakeCaseType,
      products,
    }
    return cloneDeep(config)
  }

/**
 *
 * @param type The etch type for which the most used preparation config should be returned
 * @returns Extracts the most used preparation config given a particular etch type from the lookup table. Returns null in the following cases:
 * - Lookup table is null
 */
export const extractMostUsedPreparationConfig =
  (state, getters) =>
  (type = null) => {
    // lookup table may be null
    if (!getters.mostUsedConfigLookupTable) return null

    const snakeCaseType = type
      ? type
      : getters.mostUsedConfigLookupTable.preparation.mostSelectedEtch

    const productOptionByType =
      getters.mostUsedConfigLookupTable.preparation.productOptions.find(
        (option) => option.type === snakeCaseType
      )

    // productOptions may not contain selected type
    const products =
      productOptionByType?.products || getters.getTreatment.preparation.products

    // checkbox should not be touched when radio button is toggled
    const isEdgeBevel = type
      ? getters.getTreatment.preparation.isEdgeBevel
      : getters.mostUsedConfigLookupTable.preparation.isEdgeBevel

    const config = {
      isEdgeBevel,
      etch: snakeCaseType,
      products,
    }
    return cloneDeep(config)
  }

/**
 *
 * @param type The type for which the most used filling config should be returned
 * @param kind The kind for which the most used filling config should be returned
 * @returns Extracts the most used filling config given a particular type and kind from the lookup table. Returns null in the following cases:
 * - Lookup table is null
 */
export const extractMostUsedFillingConfig =
  (state, getters) =>
  (type = null, kind = null) => {
    // lookup table may be null
    if (!getters.mostUsedConfigLookupTable) return null

    const snakeCaseType = type
      ? type
      : getters.mostUsedConfigLookupTable.filling.mostSelectedType

    const kindOptionByType =
      getters.mostUsedConfigLookupTable.filling.properties.kindOptions.find(
        (option) => option.type === snakeCaseType
      )

    const fillingKind = kind
      ? kind
      : kindOptionByType?.kind || getters.getTreatment.filling.properties.kind // kindOptions for given type may not be present

    const productOptionByTypeAndKind =
      getters.mostUsedConfigLookupTable.filling.properties.productOptions.find(
        (option) => option.type === snakeCaseType && option.kind === fillingKind
      )

    // productOptions for given (type, kind) tuple may not be present
    const products =
      productOptionByTypeAndKind?.products ||
      getters.getTreatment.filling.properties.products

    const config = {
      type: snakeCaseType,
      properties: {
        kind: fillingKind,
        products,
      },
    }
    return cloneDeep(config)
  }

/**
 * Get preparation etching types
 * @param {object} state - The vuex state object
 * @return {String[]} preparation etching types
 */
export const getPreparationEtchingTypes = (state) =>
  state.preparationEtchingTypes

/**
 * Get filling types
 * @param {object} state - The vuex state object
 * @return {String[]} filling types
 */
export const getFillingTypes = (state) => state.fillingTypes
export const getFillingKinds = (state) => state.fillingKinds

/**
 * Get filling types
 * @param {object} state - The vuex state object
 * @param {object} getters - The vuex getters object
 * @param {object} rootState - The vuex root state object
 * @param {object} rootGetters - The vuex root getters object
 * @return {String[]} filling types
 */
export const getActiveToothFindings = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const toothNumber = getters.getTreatment.tooth.number
  if (!toothNumber) return []

  const toothWithFindings = rootGetters[
    'clinicalRecords/teethWithFindings'
  ].find((tooth) => tooth.position === parseInt(toothNumber)) // TODO: format number as integer and use initial value null
  return toothWithFindings?.findings.filter((finding) => finding.visible) || []
}
