<template>
  <v-card :flat="flat" :class="[mb, { flat: flat }]">
    <header
      class="d-flex flex-wrap d-print-block mt-2-print align-center gap"
      :class="{ 'header-padding': !flat }"
    >
      <div>
        <slot name="title">
          <h5 class="text-h5">
            <div v-if="title" class="print-hidden">
              {{ $t(title) }}
            </div>
            <h5
              v-if="printableTitle || title"
              class="text-h5 hidden d-print-block"
            >
              {{ printableTitle || $t(title) }}
            </h5>
          </h5>
        </slot>
        <slot name="subtitle">
          <h6 v-if="subtitle" class="text-h5 text--disabled">
            {{ subtitle }}
          </h6>
        </slot>
      </div>
      <v-spacer />
      <div class="d-flex flex-wrap align-center gap d-print-block">
        <slot name="actions" />
      </div>
    </header>
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    printableTitle: {
      type: String,
      required: false,
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    // TODO: This prop is now only used for the patient-master-data component
    // Once we can align the patient dashboard, we should get rid of it.
    mb: {
      type: String,
      default: 'mb-8',
    },
  },
}
</script>

<style lang="scss" scoped>
.flat {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.header-padding {
  padding: var(--card-spacing-inner) !important;
}

.gap {
  gap: 8px;
}
</style>
