<template>
  <input
    ref="fileInput"
    type="file"
    class="hidden"
    capture="environment"
    accept="image/*"
    @change="$emit('change', $event)"
  />
</template>

<script>
export default {
  methods: {
    open() {
      this.$refs.fileInput.click()
    },
  },
}
</script>
