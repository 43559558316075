<template>
  <v-select
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    :items="sortedLanguages"
    :label="computedLabel"
    v-on="defaultHandlers"
  />
</template>



<script lang="ts">
import { LanguageEnum } from '@/graphql/generated/graphql';
import { useTranslation } from '@/i18n/i18n';
import { computed } from '@vue/composition-api';
import dInputComponentMixin from './d-input-component-mixin';
const __sfc_main = {
  mixins: [dInputComponentMixin]
};
__sfc_main.props = {
  label: String
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    t,
    i18next
  } = useTranslation(['common']);
  const props = __props;
  const computedLabel = computed(() => {
    return props.label || t('language');
  });
  const sortedLanguages = computed(() => {
    const items = Object.values(LanguageEnum);
    const currentLanguage = i18next.language;
    if (isLanguage(currentLanguage)) {
      const index = items.indexOf(currentLanguage);
      if (index > -1) {
        items.splice(index, 1);
        items.unshift(currentLanguage);
      }
    }
    return items.map(lang => ({
      value: lang,
      text: t(`languages.${lang}`)
    }));
  });
  const isLanguage = (value: string): value is LanguageEnum => {
    return Object.values<string>(LanguageEnum).includes(value);
  };
  return {
    computedLabel,
    sortedLanguages
  };
};
export default __sfc_main;
</script>
