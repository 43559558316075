<template>
  <d-overflow-menu v-if="hasSlots">
    <slot />
  </d-overflow-menu>
</template>

<script>
export default {
  data() {
    return {
      hasSlots: false,
    }
  },
  mounted() {
    for (const slotName in this.$slots) {
      this.$slots[slotName].forEach((slot) => {
        if (slot.tag && slot.context) {
          return (this.hasSlots = true)
        }
      })
    }
  },
}
</script>
