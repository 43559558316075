// Extracted from vue-turbolinks package
var turbolinksAdapterMixin = {
  beforeMount: function () {
    // If this is the root component, we want to cache the original element contents to replace later
    // We don't care about sub-components, just the root
    // We assume the Vue instance is a initialise-once App given a turbolinks config
    // on the element, see VueHelper#vue

    if (
      this === this.$root &&
      this.$el &&
      !this.$el?.dataset?.turbolinksPermanent
    ) {
      const turbolinksEvent =
        this.$options.turbolinksDestroyEvent || 'turbolinks:visit'
      const instance = this

      document.addEventListener(turbolinksEvent, function teardown() {
        instance.$destroy()
        document.removeEventListener(turbolinksEvent, teardown)
      })

      // cache original element
      this.$turbolinksCachedHTML = this.$el.outerHTML
      // register root hook to restore original element on destroy
      this.$once('hook:destroyed', function () {
        this.$el.outerHTML = this.$turbolinksCachedHTML
      })
    }
  },
}

function plugin(Vue) {
  // Install a global mixin
  Vue.mixin(turbolinksAdapterMixin)
}

export { turbolinksAdapterMixin }
export default plugin
