
<div class="$attrs.class">
  <v-menu
    :key="datePickerKey"
    v-model="pickerVisible"
    offset-y
    nudge-bottom="1"
    transition="fade-transition"
    min-width="290px"
    :close-on-click="closeable"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-text-field
        ref="textField"
        class="text-input"
        :class="{ 'inactive-text-color': inactive }"
        v-bind="{ ...$attrs, ...defaultInputBindings }"
        :value="text"
        v-on="{ ...on, ...defaultHandlers }"
        @input="setText"
        @blur="resetText"
        @click="$emit('click')"
      ></v-text-field>
    </template>

    <v-date-picker
      :value="model"
      no-title
      scrollable
      v-bind="$attrs"
      color="primary"
      :events="events"
      :locale="$i18n.i18next.language"
      first-day-of-week="1"
      @input="pickerChanged($event)"
      v-on="defaultHandlers"
    >
      <v-spacer></v-spacer>
      <v-btn text @click="closePicker">{{ $t('common:close') }}</v-btn>
    </v-date-picker>
  </v-menu>
</div>
