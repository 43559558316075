import RestEntity from './rest-entity'

class EventType extends RestEntity {
  entityName = 'event_type'

  async getBookingType() {
    const { event_type } = await this.get('booking_available', {})
    return event_type
  }
}

export default new EventType()
