<template>
  <fragment v-if="patient && patient.permissions.canInspect">
    <li
      class="navigation__item navigation__item--inner navigation__item--patient"
    >
      <a :href="`/patients/${patientId}`" class="navigation__link">
        <p class="navigation-patient__name">
          {{ `${patient.title || ''} ${patient.prename} ${patient.name}` }}
        </p>
        <p class="navigation-patient__code">
          {{ patient.no }}
        </p>
        <p class="navigation-patient__dob">
          <fragment v-if="patient.dob">
            {{ patient.dob | date }}
          </fragment>
          <span v-if="patient.sex" class="text-uppercase">{{
            `(${patient.sex})`
          }}</span>
        </p>
      </a>
    </li>
    <li v-if="patient.diseases" class="navigation-patient__cave">
      <span class="navigation-patient__cave-label">Cave!</span>
      {{ patient.diseases }}
    </li>
  </fragment>
</template>

<script>
export default {
  props: {
    patient: { type: Object, default: null },
    patientId: { type: Number, required: true },
  },
}
</script>
<style scoped>
.text-uppercase {
  text-transform: uppercase;
}
</style>
