import deburr from 'lodash/deburr'
import escapeRegExp from 'lodash/escapeRegExp'

const highlight = (
  text,
  queryWords,
  options = { normalize: false, wrapperClass: 'highlighter--underline' }
) => {
  if (!text) {
    return ''
  }
  let modifiedText = text
  let normalizedText = modifiedText
  if (options.normalize) {
    // é => e
    normalizedText = deburr(modifiedText)
  }

  queryWords.forEach((term) => {
    const unmodifiedTextRegex = new RegExp(escapeRegExp(term), 'ig')
    const unmodifiedTextRegexMatch = modifiedText.match(unmodifiedTextRegex)
    let normalizedRegex, normalizedRegexMatch

    if (unmodifiedTextRegexMatch) {
      modifiedText = modifiedText.replace(
        unmodifiedTextRegex,
        `<>${unmodifiedTextRegexMatch[0]}</>`
      )
    } else if (options.normalize) {
      // normalizedText can change through modifiedText modifications, recheck
      normalizedText = deburr(modifiedText)
      // multimatch highlighting for normalized words is currently not supported
      normalizedRegex = new RegExp(escapeRegExp(deburr(term)), 'i')
      normalizedRegexMatch = normalizedText.match(normalizedRegex)

      if (normalizedRegexMatch) {
        modifiedText = `
            ${modifiedText.substring(0, normalizedRegexMatch.index)}
            <>${modifiedText.substring(
              normalizedRegexMatch.index,
              normalizedRegexMatch.index + normalizedRegexMatch[0].length
            )}</>
            ${modifiedText.substring(
              normalizedRegexMatch.index + normalizedRegexMatch[0].length
            )}
          `
      }
      return modifiedText
    }
  })

  return modifiedText
    .replaceAll('<>', `<span class="${options.wrapperClass}">`)
    .replaceAll('</>', '</span>')
}

export { highlight }
