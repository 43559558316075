<template>
  <v-textarea
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    v-on="{ ...$listeners, ...defaultHandlers }"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

    <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
  </v-textarea>
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'

export default {
  mixins: [dInputComponentMixin],
}
</script>

<style scoped>
::v-deep .v-input__append-inner {
  margin-top: 4px !important;
}
</style>
