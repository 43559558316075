/**
 * Provides a way to handle click events outside of an element
 *
 * Usage:
 * <button v-click-outside="myEventHandler">My button</button>
 */
export default {
  bind(el, binding) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      const warn = `[click-outside:] provided expression ${binding.expression} is not a function, but has to be`

      console.warn(warn)
    }
    // Define handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    setTimeout(() => {
      document.addEventListener('click', handler)
    })
  },

  unbind: function (el) {
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  },
}
