<template>
  <div class="d-flex justify-space-between mini-app-header">
    <div class="mini-app-header__title">{{ $t(title) }}</div>
    <div class="d-flex align-center">
      <v-fade-transition>
        <span v-if="launcher.lastSaved" class="last-saved">{{
          formatTime(launcher.lastSaved)
        }}</span>
      </v-fade-transition>
      <component :is="customHeaderComponentName()" v-if="customHeader" />
      <d-btn v-else label="close" @click="launcher.close" />
    </div>
  </div>
</template>

<script>
export default {
  i18nOptions: {
    namespaces: ['common', 'mini_apps'],
  },
  inject: ['launcher'],
  props: {
    title: { type: String, required: true },
    customHeader: { type: String, default: null },
  },
  methods: {
    formatTime(dateOrString) {
      if (!dateOrString) return
      return this.$t('saved_on', {
        time: this.$l(Date.parse(dateOrString), 'LTS'),
      })
    },
    customHeaderComponentName() {
      return `mini-app-header-${this.customHeader}`
    },
  },
}
</script>

<style lang="scss" scoped>
.mini-app-header {
  padding: 48px;
  padding-bottom: 24px;

  &__title {
    font-size: 32px;
  }
}

.last-saved {
  display: inline-block;
  margin-right: 12px;
}
</style>
