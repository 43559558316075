
<div class="d-searchable-dropdown">
  <d-autocomplete
    auto-select-first
    :clearable="false"
    :append-icon="'mdi-chevron-down'"
    :height="'44px'"
    :hide-details="true"
    :allow-new-item="false"
    v-bind="$attrs"
    v-on="$listeners"
  />
</div>
