<template>
  <div class="data-list-item">
    <v-row class="my-0">
      <v-col class="data-list-item__label">
        <div class="pl-6">
          <slot name="label">
            <strong v-if="!tooltip">
              {{ $t(label) }}
            </strong>
            <div v-else>
              <span class="d-flex align-center justify-start">
                <strong class="mr-1">{{ $t(label) }}</strong>
                <v-icon small class="tooltip-icon">mdi-information</v-icon>
              </span>
              <d-tooltip right max-width="300" activator=".tooltip-icon">
                <span>{{ $t(tooltip) }}</span>
              </d-tooltip>
            </div>
          </slot>
        </div>
      </v-col>
      <v-col class="data-list-item__value">
        <div class="pr-6">
          <slot>
            {{ value }}
          </slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/legacy/stylesheets/_variables.scss';

.data-list-item {
  border-bottom: 1px solid $gray-1;

  &:last-child {
    border-bottom: 0;
  }

  &__label {
    max-width: 160px;
  }

  &__label,
  &__value {
    line-height: 24px;
  }
}
strong {
  font-weight: 400;
}
</style>
