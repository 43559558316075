
<v-menu :max-height="maxHeight" offset-y>
  <template #activator="{ on }">
    <v-btn
      :disabled="disabled"
      class="overflow-menu-btn"
      icon
      v-on="on"
      @click.stop
    >
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </template>
  <v-card list>
    <slot />
  </v-card>
</v-menu>
