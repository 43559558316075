<!-- After upgrading to Vue 3, this component may be replaced by global configuration of v-tooltip props. -->
<template>
  <v-tooltip v-bind="{ ...defaultAttrs, ...$attrs }">
    <template #activator="slotProps">
      <slot name="activator" v-bind="slotProps" />
    </template>
    <slot />
  </v-tooltip>
</template>
<script>
export default {
  computed: {
    defaultAttrs() {
      return {
        openDelay: 250,
      }
    },
  },
}
</script>
