
<div>
  <v-autocomplete
    v-model="model"
    v-bind="defaultInputBindings"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :no-data-text="noDataTextTranslated"
    :cache-items="false"
    :class="cssClassName"
    item-text="label"
    item-value="id"
    no-filter
    v-on="defaultHandlers"
    @input="$emit('input', $event)"
  >
  </v-autocomplete>
</div>
