
<v-list-item
  :disabled="props.disabled"
  :href="props.href"
  :target="props.target"
  v-on="listeners"
>
  <v-list-item-title>
    {{ parent.$t(props.title) }}
  </v-list-item-title>
  <slot></slot>
</v-list-item>
