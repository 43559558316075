<template>
  <svg class="d-icon" focusable="false" :class="rootClass">
    <use v-bind="{ 'xlink:href': symbolName }" />
  </svg>
</template>

<script>
export default {
  props: {
    symbol: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  computed: {
    rootClass() {
      const iconSizeClasses = {
        small: 'd-icon--small',
        medium: 'd-icon--medium',
        large: 'd-icon--large',
        xLarge: 'd-icon--x-large',
      }
      return iconSizeClasses[this.size]
    },
    symbolName() {
      return `#${this.symbol}`
    },
  },
}
</script>
<style lang="scss">
.d-icon {
  // Vars
  $icon-sizes: (
    'small': 15px,
    'medium': 24px,
    'large': 30px,
    'x-large': 40px,
  );
  // Support

  // Module
  & {
    pointer-events: none;
    display: inline-block;
    width: 100%;
    height: 100%;
    fill: none;
  }

  // Facets
  & {
    // Sizes
    @each $key, $value in $icon-sizes {
      &.d-icon--#{$key} {
        width: $value;
        height: $value;
      }
    }
  }
}
</style>
