<template>
  <fragment>
    <slot v-if="readOnly">
      {{ value || placeholder }}
    </slot>
    <v-menu
      v-if="!readOnly"
      v-model="menuOpen"
      bottom
      offset-y
      :close-on-content-click="false"
      small
      persistent
      @keydown.esc="cancel"
    >
      <template #activator="{ on }">
        <span
          class="editable-area"
          :class="{
            'editable-area-active': menuOpen,
            placeholder: !value,
          }"
          v-on="on"
        >
          <slot v-if="value">
            {{ value }}
          </slot>
          <slot v-if="!value" name="placeholder">
            {{ $t(placeholder) }}
          </slot>
          <slot name="icon"></slot>
        </span>
      </template>
      <d-form
        v-if="menuOpen"
        :id="id"
        ref="form"
        :validation="{ editable: validation }"
        :form-data.sync="form"
        @valid-submit="save"
      >
        <template #default="{ valid }">
          <v-card>
            <v-card-text>
              <slot name="input">
                <component
                  :is="inputComponent"
                  single-line
                  autofocus
                  name="editable"
                  v-bind="inputComponentProps"
                  @keydown.enter="save"
                />
              </slot>
            </v-card-text>
            <v-card-actions class="pt-0 pb-4 px-4">
              <v-spacer />
              <d-btn role="secondary" label="cancel" @click="cancel" />
              <d-btn
                type="submit"
                :disabled="!valid"
                :form="id"
                label="save"
                @click="save"
              />
            </v-card-actions>
          </v-card>
        </template>
      </d-form>
    </v-menu>
  </fragment>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  i18nOptions: {
    namespaces: ['common'],
  },
  props: {
    value: {
      type: null, // Allows any type
      required: true,
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    inputComponent: {
      type: [Object, String],
      default: 'd-input-text',
    },
    inputComponentProps: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'not_set',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        editable: null,
      },
      menuOpen: false,
      id: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setEditValue()
      },
    },
    menuOpen(isOpen) {
      if (!isOpen) {
        this.cancel()
      }
    },
  },
  mounted() {
    this.id = 'form' + this._uid
  },
  methods: {
    setEditValue() {
      this.form.editable = cloneDeep(this.value)
    },
    save() {
      this.menuOpen = false
      this.$emit('input', this.form.editable)
    },
    cancel() {
      this.menuOpen = false
      this.setEditValue()
      this.$emit('cancel')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/legacy/stylesheets/variables';

::v-deep .v-text-field__details {
  margin-bottom: 0 !important;
}

.editable-area {
  cursor: pointer;
  background: #f4f4f4;
  padding: 5px 8px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #e1e1e1;
}
.editable-area:hover {
  background-color: $gray-3 !important;
}
.editable-area-active {
  background-color: $gray-3 !important;
}

.full-width-value .editable-area {
  display: block;
}

.placeholder {
  opacity: 0.5;
}
</style>
