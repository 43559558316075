<template>
  <v-select
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs, noDataText }"
    :items="items"
    :menu-props="menuProps"
    :item-text="itemText"
    :item-value="itemValue"
    v-on="defaultHandlers"
  >
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-select>
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => {},
    },
    noDataText: {
      type: String,
      required: false,
      default() {
        return this.$t('noValues')
      },
    },
    itemText: {
      type: [Array, String, Function],
      default: 'text',
    },
    itemValue: {
      type: [Array, String, Function],
      default: 'value',
    },
  },
}
</script>
