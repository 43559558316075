<template>
  <v-checkbox
    v-model="model"
    v-bind="defaultInputBindings"
    v-on="defaultHandlers"
  />
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
}
</script>
