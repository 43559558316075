// Mini app definitions
// This is static configuration, runtime configuration can be
// provided to the components via the launcher:
//
// $launcher.openMiniApp(name, {...runtimeConfiguration, passed as props})
export const apps = {
  finding: {
    name: 'finding',
    width: 1296,
    component: 'examination-container',
  },
  tooth_appeal: {
    name: 'tooth_appeal',
    width: 1296,
    component: 'tooth-appeal-miniapp',
  },
  parostatus: {
    name: 'parostatus',
    width: 1680,
    component: 'paro-status-container',
  },
  hygiene: {
    name: 'hygiene',
    width: 1200,
    component: 'hygiene-container',
  },
  mobility: {
    name: 'mobility',
    width: 1300,
    component: 'denture-app-container',
  },
  vitality: {
    name: 'vitality',
    width: 1300,
    component: 'denture-app-container',
  },
  psi: {
    name: 'psi',
    width: 960,
    component: 'quick-probe-container',
  },
  erosion: {
    name: 'erosion',
    width: 1080,
    component: 'quick-probe-container',
  },
  class_examination_normal: {
    name: 'class_examination_normal',
    width: 1080,
    component: 'class-examination',
  },
  class_examination_final: {
    name: 'class_examination_final',
    width: 1080,
    component: 'class-examination',
  },
  filling_assistant: {
    name: 'filling_assistant',
    width: 1600,
    component: 'filling-assistant-container',
    customHeader: 'filling-assistant',
  },
  llm_form: {
    name: 'llm_form',
    width: 1080,
    component: 'llm-form-container',
  },
}
