
<div class="d-layout">
  <aside class="d-layout__sidebar">
    <router-link
      v-for="(route, index) in routes"
      :key="index"
      class="d-layout__sidebar-link"
      :to="route.path"
    >
      {{ route.rawLabel ? route.label : $t(route.label) }}
    </router-link>
  </aside>
  <main class="d-layout__container">
    <router-view />
  </main>
</div>
