
<fragment>
  <d-form-label v-if="label" :text="label" />
  <fragment v-if="useTabs">
    <v-tabs>
      <v-tab
        v-for="(_value, locale) in sortedLanguages(model)"
        :key="locale"
        >{{ locale }}</v-tab
      >

      <v-tab-item
        v-for="(_value, locale) in sortedLanguages(model)"
        :key="locale"
      >
        <v-textarea
          v-model.trim="model[locale]"
          v-bind="{ ...defaultInputBindings, ...$attrs }"
          label=""
          filled
          hide-details
          rows="25"
        />
      </v-tab-item>
    </v-tabs>
  </fragment>

  <fragment v-else>
    <v-text-field
      v-for="(_value, locale) in sortedLanguages(model)"
      v-bind="{ ...defaultInputBindings, ...$attrs }"
      :key="locale"
      v-model.trim="model[locale]"
      :label="$t(`people:lang.${locale}`)"
      hide-details
      class="mb-2"
      v-on="defaultHandlers"
    >
      <template #message="{ message }">
        <slot :name="message"></slot>
      </template>
    </v-text-field>
  </fragment>
</fragment>
