import { defineStore } from 'pinia'

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    patientNavigationCollapsed: false,
  }),
  actions: {
    togglePatientNavigation(state) {
      if (typeof state === 'undefined') {
        this.patientNavigationCollapsed = !this.patientNavigationCollapsed
        return
      }
      this.patientNavigationCollapsed = state
    },
  },
})
