<template>
  <v-autocomplete
    ref="autocomplete"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    :search-input.sync="search"
    autocomplete="off"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

    <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <template
      v-if="allowNewItem && search && !$refs.autocomplete.filteredItems.length"
      #append-item
    >
      <div
        class="px-4 v-list-item v-list-item--link theme--light"
        @click="newItemCreationCallback"
      >
        <div class="v-list-item__content">
          <span class="v-list-item__title new-item">
            {{ newItemLabel }}
          </span>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    autocompleteCallback: Function,
    allowNewItem: {
      type: Boolean,
      default: false,
    },
    newItemLabel: {
      type: String,
      default: '',
    },
    newItemCreationCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cancelToken: null,
      search: '',
      defaultAttrs: {
        'item-value': 'id',
        'item-text': 'name',
        filled: true,
        clearable: true,
        class: [this.$attrs.class, 'd-automplete-' + this._uid].join(' '),
        attach: '.d-automplete-' + this._uid,
      },
    }
  },
  watch: {
    search(val) {
      if (this.autocompleteCallback) {
        this.autocompleteCallback(val, (newToken) => {
          const oldToken = this.cancelToken
          this.cancelToken = newToken
          return oldToken
        }).finally(() => (this.cancelToken = null))
      }
    },
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus()
      this.$refs.autocomplete.$refs.menu.isActive = true
    },
    blur() {
      this.$refs.autocomplete.blur()
      this.$refs.autocomplete.$refs.menu.isActive = false
    },
    close() {
      this.$refs.autocomplete.$refs.menu.isActive = false
    },
  },
}
</script>
<style lang="scss" scoped>
.new-item {
  font-weight: 500;
}
</style>
