
<d-autocomplete
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  :item-text="memberInfo"
  :no-data-text="noDataText"
  :items="items"
  :clearable="clearable"
  :attach="false"
  :append-icon="appendIcon"
  :hide-selected="hideSelected"
  v-on="defaultHandlers"
/>
