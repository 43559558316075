<template>
  <d-autocomplete
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    :item-text="memberInfo"
    :no-data-text="noDataText"
    :items="items"
    :clearable="clearable"
    :attach="false"
    :append-icon="appendIcon"
    :hide-selected="hideSelected"
    v-on="defaultHandlers"
  />
</template>

<script>
import dInputComponentMixin from './d-input-component-mixin'
import { teamMemberTypes } from '../types'
import { mapGetters } from './../../../store/modules/common'
import { filterByLocationVisibility } from '../../core/utils'

export default {
  mixins: [dInputComponentMixin],
  i18nOptions: { namespaces: ['common', 'activerecord'] },
  props: {
    type: {
      type: String,
      default: null,
      validator: (value) =>
        !value || Object.values(teamMemberTypes).includes(value),
    },
    // filter teamMembers based on the input roles
    roles: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'models.physician',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    onlyShown: {
      type: Boolean,
      default: false,
    },
    locationId: {
      type: Number,
      default: null,
      required: false,
    },
    appendIcon: {
      type: String,
      default: '$dropdown',
      required: false,
    },
    memberInfo: {
      type: String,
      default: 'display_name',
      required: false,
    },
    selectFirstOnInvalidValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['teamMembers']),
    noDataText() {
      return this.$t('autocomplete.noData', {
        searchType: this.$t('activerecord:models.physician'),
      })
    },
    hideSelected() {
      if (this.value) {
        const isSelectedInItems = this.filteredTeamMembers.find(
          (member) => member.id === this.value
        )
        return (this.onlyShown || !!this.roles.length) && !isSelectedInItems
      } else {
        return false
      }
    },
    filteredTeamMembers() {
      let returnTeamMembers = [...this.teamMembers]
      if (this.type) {
        const teamMemberType = this.type
        returnTeamMembers = returnTeamMembers.filter(
          (member) => member.model === teamMemberType
        )
      }
      if (this.roles.length) {
        returnTeamMembers = returnTeamMembers.filter((member) =>
          this.roles.includes(member.role)
        )
      }
      if (this.onlyShown) {
        returnTeamMembers = filterByLocationVisibility(
          returnTeamMembers,
          this.locationId
        )
      }
      this.$emit('filtered-team-members', returnTeamMembers)
      return returnTeamMembers
    },
    items() {
      const items = [...this.filteredTeamMembers]
      // push selected item in the select list if
      // selected item is not in the list when onlyShow is true
      // and then hide the item, so no one can select it again
      if (this.value && this.hideSelected) {
        const currentTeamMember = this.teamMembers.find(
          (member) => member.id === this.value
        )
        if (currentTeamMember) {
          items.push(currentTeamMember)
        }
      }
      return items
    },
  },
  created() {
    // if the value does not exist in the list
    // preselect the first item instead
    if (this.model && this.selectFirstOnInvalidValue) {
      const valueInList = this.filteredTeamMembers.find(
        (teamMember) => teamMember.id === this.model
      )
      if (valueInList) {
        return
      }

      this.model =
        this.filteredTeamMembers.length > 0
          ? this.filteredTeamMembers[0].id
          : null
      this.$emit('input', this.model)
    }
  },
}
</script>
