<template>
  <d-autocomplete
    v-if="availableLocations.length > 1 || visible"
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    item-text="shortname"
    :no-data-text="noDataText"
    :items="availableLocations"
    :class="cssClassName"
    :attach="false"
    :append-icon="appendIcon"
    v-on="defaultHandlers"
  />
</template>

<script>
import dInputComponentMixin from './d-input-component-mixin'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [dInputComponentMixin],
  i18nOptions: { namespaces: ['activerecord'] },
  props: {
    label: { type: String, default: 'models.location' },
    type: { type: String, default: null },
    visible: { type: Boolean, default: false },
    preselectFromState: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: '$dropdown',
      required: false,
    },
  },
  computed: {
    ...mapGetters('common', ['lastLocationSelected']),
    ...mapState('common', ['availableLocations']),
    cssClassName() {
      // if multiple instances are rendered in the same view there needs to be a unique class to attach to
      return `d-input-location-${this._uid}`
    },
    noDataText() {
      return this.$t('autocomplete.noData', {
        searchType: this.$t('activerecord:models.location'),
      })
    },
  },
  created() {
    if (this.availableLocations.length === 1) {
      this.model = this.availableLocations[0].id
    }

    if (!this.model && this.preselectFromState && this.lastLocationSelected) {
      const locationExists = this.availableLocations.find(
        (location) => location.id == this.lastLocationSelected
      )
      if (locationExists) {
        this.model = this.lastLocationSelected
      } else {
        this.model = this.availableLocations[0].id
      }
    }
  },
}
</script>
