<template>
  <div
    v-if="patient.id"
    class="navigation__patient"
    :class="{ collapsed: navCollapsedState }"
  >
    <div v-if="isPatientNavCollapsible" class="navigation__patient--meta">
      <button
        class="patient-navigation-trigger"
        @click="togglePatientNavigation"
      >
        <svg class="btn-arrow" viewBox="0 0 6 10" fill="none">
          <path
            d="M1 9L5 5L1 1"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div class="navigation__drawer">
      <ul class="navigation__list">
        <navigation-patient-overview
          :patient-id="patient.id"
          :patient="patient.info"
        />
        <navigation-patient-links
          :patient="patient"
          :user-permissions="userPermissions"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import NavigationPatientOverview from './navigation-patient-overview.vue'
import NavigationPatientLinks from './navigation-patient-links.vue'
import { useNavigationStore } from './navigation-store'
import { get, set } from '@/services/local-setting'

export default {
  components: {
    NavigationPatientOverview,
    NavigationPatientLinks,
  },
  props: {
    patient: { type: Object, required: true },
    userPermissions: { type: Object, required: true },
  },
  data() {
    return {
      navCollapsedState: null,
    }
  },
  computed: {
    isPatientNavCollapsible() {
      return this.$featureFlag
        .feature_flags_enable_collapsible_patient_navigation
    },
  },
  beforeDestroy() {
    document.removeEventListener('turbolinks:load', this.setupCollapsableNav)
  },
  created() {
    document.addEventListener('turbolinks:load', this.setupCollapsableNav)
  },
  methods: {
    setupCollapsableNav() {
      if (!this.isPatientNavCollapsible) return
      // Apply local setting
      this.navCollapsedState = get('patient-navigation', 'collapsed')
      // Update store with local setting
      useNavigationStore().togglePatientNavigation(
        this.navCollapsedState || false
      )

      this.subscribeToPatientNavChanges()

      this.toggleMainPageContent(true)
    },
    subscribeToPatientNavChanges() {
      useNavigationStore().$subscribe((mutation, state) => {
        this.navCollapsedState = state.patientNavigationCollapsed
        set('patient-navigation', 'collapsed', this.navCollapsedState)

        this.toggleMainPageContent()
      })
    },
    togglePatientNavigation() {
      useNavigationStore().togglePatientNavigation()
    },
    toggleMainPageContent(onLoad) {
      const mainLayoutEl = document.querySelector(
        '.ui-layout.ui-layout--patient'
      )
      if (!mainLayoutEl) return
      if (onLoad) {
        // remove the css transition on padding-left if page load
        mainLayoutEl.classList.add('no-transition')
        setTimeout(() => mainLayoutEl.classList.remove('no-transition'), 500)
      }
      mainLayoutEl.classList.toggle(
        'patient-nav-collapsed',
        this.navCollapsedState
      )
    },
  },
}
</script>
