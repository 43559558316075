
<v-dialog
  v-model="dialogState"
  fullscreen
  hide-overlay
  eager
  transition="dialog-bottom-transition"
>
  <div class="mini-app" :class="`mini-app-${title}`">
    <div
      class="mini-app__container"
      :style="`max-width: ${maxContentWidth}px`"
    >
      <mini-app-header :title="title" :custom-header="customHeader" />
      <div class="mini-app__scroll-container">
        <slot></slot>
      </div>
    </div>
  </div>
</v-dialog>
