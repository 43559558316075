
<v-autocomplete
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  :items="items"
  :item-text="itemText"
  :item-value="itemValue"
  v-on="defaultHandlers"
  @click:append="$emit('click:append', $event)"
  @click:append-outer="$emit('click:append-outer', $event)"
/>
