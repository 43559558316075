
<d-autocomplete
  v-if="availableLocations.length > 1 || visible"
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  item-text="shortname"
  :no-data-text="noDataText"
  :items="availableLocations"
  :class="cssClassName"
  :attach="false"
  :append-icon="appendIcon"
  v-on="defaultHandlers"
/>
