<template>
  <fragment>
    <d-form-label v-if="label" :text="label" />
    <fragment v-if="useTabs">
      <v-tabs>
        <v-tab
          v-for="(_value, locale) in sortedLanguages(model)"
          :key="locale"
          >{{ locale }}</v-tab
        >

        <v-tab-item
          v-for="(_value, locale) in sortedLanguages(model)"
          :key="locale"
        >
          <v-textarea
            v-model.trim="model[locale]"
            v-bind="{ ...defaultInputBindings, ...$attrs }"
            label=""
            filled
            hide-details
            rows="25"
          />
        </v-tab-item>
      </v-tabs>
    </fragment>

    <fragment v-else>
      <v-text-field
        v-for="(_value, locale) in sortedLanguages(model)"
        v-bind="{ ...defaultInputBindings, ...$attrs }"
        :key="locale"
        v-model.trim="model[locale]"
        :label="$t(`people:lang.${locale}`)"
        hide-details
        class="mb-2"
        v-on="defaultHandlers"
      >
        <template #message="{ message }">
          <slot :name="message"></slot>
        </template>
      </v-text-field>
    </fragment>
  </fragment>
</template>



<script lang="ts">
import type { TranslatedString } from '@/graphql/types/TranslatedString';
import { useTranslation } from '@/i18n/i18n';
import dInputComponentMixin from './d-input-component-mixin';
const __sfc_main = {
  mixins: [dInputComponentMixin]
};
__sfc_main.props = {
  useTabs: {
    key: "useTabs",
    required: false,
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    i18next
  } = useTranslation();

  /**
   * Orders language keys so that the current language is always the first language.
   */
  const sortedLanguages = (model: TranslatedString) => {
    const currentLanguage = (i18next.language.charAt(0).toUpperCase() + i18next.language.slice(1) as keyof TranslatedString);
    if (currentLanguage in model) {
      const {
        [currentLanguage]: value,
        ...rest
      } = model;
      return {
        [currentLanguage]: value,
        ...rest
      };
    }
    return model;
  };
  return {
    sortedLanguages
  };
};
export default __sfc_main;
</script>
