import { createNamespacedHelpers } from 'vuex'
import feedService from '../../../services/feed'
import localSettings from '../../../services/local-setting'
import toothSchemaService from '@/services/tooth-schema'
import {
  quadrantPositionsFromTeeth,
  getQuadrantPositionsFromTeethWithMissing,
} from '../../../modules/tooth-schema/positions'
import { ToothSelector } from '../../../modules/tooth-schema/teeth'
import {
  hasAgenesis,
  hasMissingTooth,
} from '../../../modules/tooth-schema/helpers'

export const { mapState, mapActions, mapMutations, mapGetters } =
  createNamespacedHelpers('clinicalRecords')

const initialState = () => ({
  records: [],
  patientId: null,
  filter: {
    administrative: true, // currently not used
    medical: true,
    position: true,
    document: true,
    appointment: true,
    event: true,
    free_text: true,
    bid: true,
    invoice: true,
    diagrams: true,
    patient: true,
    outbox_items: true,
    anamnesis_forms: true,
    steri_codes: false,
    ...localSettings.get('kg', 'filter'),
  },
  diagram: {
    diagnostics: true,
    parostatus_schema: false,
    ...localSettings.get('kg', 'diagram'),
  },
  query: '',
  toothSchema: null,
  dayFilter: 'all',
})

const actions = {
  async refresh({ commit, state }) {
    const query = { ...state.filter, by_text: state.query }
    const { records } = await feedService.fetch(state.patientId, {
      ...query,
    })
    commit('setRecords', records)
  },
  applyFilter({ commit }, filter) {
    // We want to only persist the following filters
    const {
      administrative,
      administrative_app,
      medical,
      position,
      document,
      appointment,
      event,
      free_text,
      bid,
      invoice,
      diagrams,
      query,
      patient,
      anamnesis_forms,
      outbox_items,
      steri_codes,
    } = filter

    localSettings.set('kg', 'filter', {
      administrative,
      administrative_app,
      medical,
      position,
      document,
      appointment,
      event,
      free_text,
      bid,
      invoice,
      diagrams,
      patient,
      anamnesis_forms,
      outbox_items,
      steri_codes,
    })
    commit('setFilter', {
      administrative,
      administrative_app,
      medical,
      position,
      document,
      appointment,
      event,
      free_text,
      bid,
      invoice,
      diagrams,
      patient,
      anamnesis_forms,
      outbox_items,
      steri_codes,
    })
    commit('setQuery', query)
  },
  applyDiagram({ commit }, diagram) {
    // We want to only persist the following filters
    const { diagnostics, parostatus_schema, query } = diagram

    localSettings.set('kg', 'diagram', {
      diagnostics,
      parostatus_schema,
    })
    commit('setDiagram', {
      diagnostics,
      parostatus_schema,
    })
    commit('setQuery', query)
  },
  async fetchToothSchema({ commit, state }) {
    const { tooth_schema } = await toothSchemaService(
      state.patientId
    ).fetchAll()
    commit('setToothSchema', tooth_schema)
  },
  dayFilter({ commit }, state) {
    commit('setDayFilter', state)
  },
}

const mutations = {
  setPatientId(state, value) {
    state.patientId = value
  },
  reset(state) {
    state.patientId = null
    state.records = []
    state.query = ''
  },
  setRecords(state, records) {
    state.records = records
  },
  setFilter(state, filter) {
    state.filter = filter
  },
  setQuery(state, value) {
    state.query = value
  },
  setToothSchema(state, value) {
    state.toothSchema = value
  },
  setDayFilter(state, value) {
    state.dayFilter = value
  },
  setDiagram(state, diagram) {
    state.diagram = diagram
  },
}

const getters = {
  toothSchema: (state) => state.toothSchema,
  teeth: (state) => {
    return quadrantPositionsFromTeeth(state.toothSchema.teeth)
  },
  teethWithMissing: (state) => {
    return getQuadrantPositionsFromTeethWithMissing(state.toothSchema.teeth)
  },
  getDayFilter: ({ dayFilter }) => dayFilter,
  teethWithFindings: (state, getters, rootState, rootGetters) =>
    state.toothSchema.teeth.map((position) => {
      return {
        position: position,
        findings: rootGetters['findings/getFindingsForTeeth'](
          new ToothSelector(position)
        ),
      }
    }),
  teethWithActiveFindings: (state, getters) =>
    getters.teethWithFindings.filter(
      (toothWithFinding) =>
        (toothWithFinding?.findings.filter((finding) => finding.visible) || [])
          .length
    ),
  teethWithFindingCodes: (state, getters) =>
    getters.teethWithFindings.map((tooth) => ({
      position: tooth.position,
      findingCodes: tooth.findings.map((finding) => finding.categoryCode),
    })),
  // This getter provides the positions of teeth that have position !== null but should be disabled because of specific findings attached to them
  disabledToothPositions: (state, getters) =>
    getters.teethWithFindingCodes
      .filter((tooth) => hasAgenesis(tooth) || hasMissingTooth(tooth))
      .map((tooth) => tooth.position),
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  mutations,
  getters,
}
