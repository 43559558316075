import invert from 'lodash/invert'
import includes from 'lodash/includes'
import find from 'lodash/find'
import union from 'lodash/union'
import positions from './positions'
import {
  isMandibula,
  isMaxilla,
  isIncisor,
  isCanine,
} from '../tooth-schema/helpers'

export const surfaceAreas = Object.freeze({
  DISTAL: 'distal',
  BUCCAL: 'buccal',
  MESIAL: 'mesial',
  PALATAL: 'palatal',
  OCCLUSAL: 'occlusal',
})

export const toothAreas = Object.freeze({
  ROOT1: 'root-1',
  ROOT2: 'root-2',
  ROOT3: 'root-3',
  CROWN: 'crown',
})

export const areaShortNameMap = {
  [surfaceAreas.DISTAL]: 'd',
  [surfaceAreas.BUCCAL]: 'b',
  [surfaceAreas.MESIAL]: 'm',
  [surfaceAreas.PALATAL]: 'p',
  [surfaceAreas.OCCLUSAL]: 'o',
  [toothAreas.ROOT1]: 'r1',
  [toothAreas.ROOT2]: 'r2',
  [toothAreas.ROOT3]: 'r3',
  [toothAreas.CROWN]: 'c',
}

export const toothInfo = {
  18: { roots: ['d', 'm', 'p'] },
  17: { roots: ['d', 'm', 'p'] },
  16: { roots: ['d', 'm', 'p'] },
  15: { roots: [''] },
  14: { roots: ['b', 'p'] },
  13: { roots: [''] },
  12: { roots: [''] },
  11: { roots: [''] },

  21: { roots: [''] },
  22: { roots: [''] },
  23: { roots: [''] },
  24: { roots: ['b', 'p'] },
  25: { roots: [''] },
  26: { roots: ['d', 'm', 'p'] },
  27: { roots: ['d', 'm', 'p'] },
  28: { roots: ['d', 'm', 'p'] },

  38: { roots: ['d', 'm'] },
  37: { roots: ['d', 'm'] },
  36: { roots: ['d', 'm'] },
  35: { roots: [''] },
  34: { roots: [''] },
  33: { roots: [''] },
  32: { roots: [''] },
  31: { roots: [''] },

  41: { roots: [''] },
  42: { roots: [''] },
  43: { roots: [''] },
  44: { roots: [''] },
  45: { roots: [''] },
  46: { roots: ['d', 'm'] },
  47: { roots: ['d', 'm'] },
  48: { roots: ['d', 'm'] },

  55: { roots: ['d', 'm', 'p'] },
  54: { roots: ['d', 'm', 'p'] },
  53: { roots: [''] },
  52: { roots: [''] },
  51: { roots: [''] },

  61: { roots: [''] },
  62: { roots: [''] },
  63: { roots: [''] },
  64: { roots: ['d', 'm', 'p'] },
  65: { roots: ['d', 'm', 'p'] },

  75: { roots: ['d', 'm'] },
  74: { roots: ['d', 'm'] },
  73: { roots: [''] },
  72: { roots: [''] },
  71: { roots: [''] },

  81: { roots: [''] },
  82: { roots: [''] },
  83: { roots: [''] },
  84: { roots: ['d', 'm'] },
  85: { roots: ['d', 'm'] },
}

export const quadrants = ['q1', 'q2', 'q3', 'q4']

export function positionsToSchema(positions) {
  return quadrants.reduce((schema, quadrant) => {
    schema[quadrant] = positions[quadrant].map(createTooth)
    return schema
  }, {})
}

export function schemaToothByNumber(schema, toothNumber) {
  return Object.values(schema)
    .flat()
    .find(({ number }) => number === Number(toothNumber))
}

export function createTooth(number) {
  return {
    number,
    state: null,
    areaState: {
      [surfaceAreas.MESIAL]: null,
      [surfaceAreas.DISTAL]: null,
      [surfaceAreas.BUCCAL]: null,
      [surfaceAreas.PALATAL]: null,
      [surfaceAreas.OCCLUSAL]: null,
    },
    shapeOverrides: {
      [toothAreas.ROOT1]: null,
      [toothAreas.ROOT2]: null,
      [toothAreas.ROOT3]: null,
      [toothAreas.CROWN]: null,
    },
    styleOverrides: {},
    indicatorsBack: [],
    indicatorsFront: [],
    indicatorsSpecific: {},
  }
}

export const toothSelectorRegExp = RegExp(positions.all.join('|'))
export const areaSelectorRegExp = RegExp(
  'dw|dbw|bw|mw|mbw|pw|d|b|m|p|l|o|i|r1|r2|r3|c|w',
  'gi'
)

export class ToothSelector {
  constructor(selector) {
    this.teeth = []

    if (Array.isArray(selector)) {
      selector.forEach((one) => this.add(one))
    } else {
      if (typeof selector === 'string') {
        this.string = selector
      } else if (typeof selector === 'number') {
        this.string = selector
      } else if (selector instanceof ToothSelector) {
        this.teeth = selector.teeth.map((tooth) => {
          return {
            number: tooth.number.toString(),
            areas: tooth.areas,
          }
        })
      } else if (typeof selector === 'object') {
        this.teeth = [
          {
            number: selector.number.toString(),
            areas: selector.areas || [],
          },
        ]
      } else {
        this.teeth = []
      }
    }
  }

  areaToString(number, area) {
    if (toothInfo[number] === undefined) return ''

    if (area.startsWith('root-')) {
      const rootNumber = area.split('-')[1]
      const rootPrefix = toothInfo[number].roots[rootNumber - 1]

      return `${rootPrefix}w`.toUpperCase()
    } else if (area === 'palatal') {
      if (isMaxilla(number)) return 'P'
      if (isMandibula(number)) return 'L'
    } else if (area === 'occlusal') {
      if (isIncisor(number) || isCanine(number)) {
        return 'I'
      } else {
        return 'O'
      }
    } else {
      return areaShortNameMap[area]?.toUpperCase()
    }
  }

  stringToArea(number, area) {
    if (toothInfo[number] === undefined) return ''

    let short = area.toLowerCase()

    if (short === 'w') {
      return 'root-1'
    } else if (short.endsWith('w')) {
      const rootPrefix = short.split('')[0]
      const rootNumber = toothInfo[number].roots.indexOf(rootPrefix) + 1

      if (rootNumber === 0) return ''

      return `root-${rootNumber}`
    } else {
      if (short === 'l') short = 'p'
      if (short === 'i') short = 'o'

      return invert(areaShortNameMap)[short]
    }
  }

  // This method only really exists to provide compatibility with GraphQL where we use ToothInput! type
  static stringToToothInputs(selectorString) {
    return selectorString.split(',').map((part) => {
      const toothSelectors = part.match(toothSelectorRegExp)
      if (toothSelectors === null) return
      const toothNumber = toothSelectors[0]

      const toothAreas = part.match(areaSelectorRegExp) || []
      return { toothNumber, toothAreas }
    })
  }

  get array() {
    return this.teeth.map((tooth) => {
      if (tooth.areas === undefined) return tooth.number

      const areaString = tooth.areas
        .map((area) => this.areaToString(tooth.number, area))
        .sort()
        .join('')

      return tooth.number + areaString
    })
  }

  get string() {
    return this.array.join(', ')
  }

  set string(selector) {
    if (typeof selector === 'number') {
      selector = selector.toString()
    }

    if (typeof selector !== 'string') {
      this.teeth = []
      return
    }

    const teeth = selector.split(',').map((part) => {
      const toothSelectors = part.match(toothSelectorRegExp)
      if (toothSelectors === null) return
      const number = toothSelectors[0]

      const areaSelectors = part.match(areaSelectorRegExp) || []
      const areas = areaSelectors.map((short) =>
        this.stringToArea(number, short)
      )

      return { number, areas }
    })

    this.add(teeth)
  }

  isEmpty() {
    return this.teeth.length === 0
  }

  isPresent() {
    return !this.isEmpty()
  }

  isFullTooth() {
    return this.teeth.length === 1 && this.teeth[0].areas.length === 0
  }

  includes(matcher) {
    const matcherSelector = new ToothSelector(matcher)

    if (matcherSelector.teeth.length === 0) return false

    return matcherSelector.teeth.every((matcherTooth) => {
      const tooth = find(this.teeth, { number: matcherTooth.number })
      if (!tooth) return false

      return matcherTooth.areas.every((area) => includes(tooth.areas, area))
    })
  }

  add(selector) {
    const addition = new ToothSelector(selector)

    addition.teeth.forEach((tooth) => {
      const existing = find(this.teeth, { number: tooth.number })

      if (existing) {
        existing.areas = union(existing.areas, tooth.areas)
      } else {
        this.teeth.push(tooth)
      }
    })

    return this
  }

  removeFromTeeth(existing) {
    this.teeth = [
      ...this.teeth.filter((tooth) => tooth.number !== existing.number),
    ]
  }

  remove(selector) {
    const removal = new ToothSelector(selector)

    removal.teeth.forEach((tooth) => {
      const existing = find(this.teeth, { number: tooth.number })

      if (existing) {
        if (existing.areas.length === 0 && tooth.areas.length === 0) {
          this.removeFromTeeth(existing)
        } else if (existing.areas.length > 0) {
          existing.areas = existing.areas.filter(
            (area) => !tooth.areas.includes(area)
          )

          if (existing.areas.length === 0) {
            this.removeFromTeeth(existing)
          }
        }
      }
    })

    return this
  }

  toggle(selector) {
    if (this.includes(selector)) {
      this.remove(selector)
    } else {
      this.add(selector)
    }

    return this
  }
}
