import { get } from '@/libs/axiosWrapper'

class AutocompleteList {
  countries = null
  cantons = null

  async fetchCountries() {
    if (!this.countries) {
      this.countries = await get('/auto_complete/countries')
    }

    return this.countries
  }

  async fetchCantons() {
    if (!this.cantons) {
      this.cantons = await get('/auto_complete/cantons')
    }

    return this.cantons
  }
}

export default new AutocompleteList()
