
<div
  v-if="patient.id"
  class="navigation__patient"
  :class="{ collapsed: navCollapsedState }"
>
  <div v-if="isPatientNavCollapsible" class="navigation__patient--meta">
    <button
      class="patient-navigation-trigger"
      @click="togglePatientNavigation"
    >
      <svg class="btn-arrow" viewBox="0 0 6 10" fill="none">
        <path
          d="M1 9L5 5L1 1"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
  <div class="navigation__drawer">
    <ul class="navigation__list">
      <navigation-patient-overview
        :patient-id="patient.id"
        :patient="patient.info"
      />
      <navigation-patient-links
        :patient="patient"
        :user-permissions="userPermissions"
      />
    </ul>
  </div>
</div>
