<template>
  <v-autocomplete
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    v-on="defaultHandlers"
    @click:append="$emit('click:append', $event)"
    @click:append-outer="$emit('click:append-outer', $event)"
  />
</template>
<script>
import dInputComponentMixin from './d-input-component-mixin'

/**
 * dInputAutocomplete
 * @mixes dInputComponentMixin
 *
 * @prop {String} itemValue Set property of items's value - must be primitive. Dot notation is supported. - defaults to `value`
 * @prop {String} itemText Set property of items's text value - defaults to `text`
 *
 * ```vue
 * <d-form :form-data="{ id: 1, some_relation_id: 1 }">
 *   <d-input-autocomplete
 *     name="some_relation_id"
 *     label="attributes.example_model.some_relation"
 *     clearable
 *   />
 * </d-form>
 * ```
 */
const dInputAutocomplete = {
  mixins: [dInputComponentMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemValue: { type: String, default: 'value' },
    itemText: { type: String, default: 'text' },
  },
}
export default dInputAutocomplete
</script>
