<template>
  <v-card-text class="py-0 text--primary" :class="spacing">
    <slot />
  </v-card-text>
</template>
<script>
export default {
  props: {
    spacing: {
      type: String,
      default: 'px-6',
    },
  },
}
</script>
