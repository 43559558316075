import { visit } from 'turbolinks'
import compact from 'lodash/compact'
import moment from 'moment'
import { defaultAvatarForPerson } from '../core/utils'
import grayCircle from './default-avatar.png'
import featureFlag from '@/services/feature-flag'

// Command interface:
// {
//   id: String,
//   label: String,
//   command: Function
//   icon: String || null
// }
// For some reason we can't destructure "vm" like "{ $t }"
// because the destructured props lose their scope

const createPatientCommand = (vm) => ({
  id: 'create_patient',
  label: vm.$t('create_patient'),
  icon: 'mdi-plus',
  command: () => visit(`/patients/new`),
})

export const createGlobalCommands = (vm) =>
  compact([
    !featureFlag.feature_flags_enable_external_patient_data &&
      createPatientCommand(vm),
  ]).map((obj) => ({ ...obj, type: 'global' }))

export const createPatientCommands = (vm) =>
  compact([
    !featureFlag.feature_flags_enable_external_patient_data &&
      createPatientCommand(vm),
  ]).map((obj) => ({ ...obj, type: 'global' }))

export const createPatientsCommands = (patients) =>
  patients.map(
    ({
      no,
      street,
      houseNo,
      age,
      additionalAddress,
      city,
      zip,
      phone,
      mobile,
      id,
      thumbnailUrl,
      dob,
      fullname,
      title,
      sex,
      isArchived,
      permissions,
      defaultLocation,
    }) => ({
      fullNameWithNo: compact([compact([title, fullname]).join(' '), no]).join(
        ', '
      ),
      label: compact([fullname, no]).join(', '),
      profileThumbnailUrl:
        thumbnailUrl ||
        defaultAvatarForPerson({ age, sex }, true) ||
        grayCircle,
      id,
      permissions,
      defaultLocation,
      fullAddress: compact([
        compact([street, houseNo]).join(' '),
        additionalAddress,
        compact([zip, city]).join(' '),
      ]).join(', '),
      additionalInfo: compact([
        dob ? moment(dob).format('DD.MM.YYYY') : '',
        mobile,
        phone,
      ]).join(', '),
      type: 'patient',
      command: () => {
        visit(`/patients/${id}`)
      },
      isArchived,
    })
  )
