
<div class="quick-command">
  <d-autocomplete
    ref="query"
    v-model="selected"
    v-click-outside="blur"
    :search-input.sync="query"
    :items="items"
    :menu-props="{ maxHeight: '400px', minWidth: '640px' }"
    :placeholder="$t('quicksearch')"
    :filled="false"
    item-text="label"
    item-value="id"
    append-icon=""
    hide-no-data
    height="90"
    background-color="#fff"
    class="quick-command__input"
    open-on-clear
    return-object
    no-filter
    clearable
    solo
    auto-select-first
    @keydown.esc="$emit('cancel')"
  >
    <template #prepend-inner>
      <d-icon symbol="icon-search" />
    </template>
    <template #item="{ item }">
      <template v-if="item.type === 'patient'">
        <div
          class="d-flex"
          :class="{
            'quick-command__disabled':
              item.isArchived || !item.permissions.canInspect,
          }"
        >
          <v-list-item-avatar class="picture">
            <img :src="item.profileThumbnailUrl" />
          </v-list-item-avatar>
          <div class="d-flex column">
            <div class="d-flex name">
              <span
                v-html="highlight(item.fullNameWithNo, { normalize: true })"
              />
              <span
                v-if="item.isArchived || !item.permissions.canInspect"
                class="quick-command__archived"
              >
                ({{
                  compact([
                    !item.permissions.canInspect &&
                      item.defaultLocation.shortname,
                    item.isArchived && $t('archived'),
                  ]).join(', ')
                }})
              </span>
            </div>
            <div class="d-flex column">
              <div class="d-flex address">
                <span v-html="highlight(item.fullAddress)"></span>
              </div>
              <div class="d-flex contact">
                <span v-html="highlight(item.additionalInfo)"></span>
              </div>
            </div>
          </div>
          <div class="actions column">
            <div class="d-flex icon" @click="editPatient(item)">
              <v-icon>mdi-pencil</v-icon>
            </div>
          </div>
        </div>
      </template>
      <template v-if="item.type === 'global'">
        <v-list-item-avatar class="picture">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.label" />
        </v-list-item-content>
      </template>
    </template>
  </d-autocomplete>
</div>
