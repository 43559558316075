/**
 * dInputAutocompleteMixin
 *
 * @mixin
 * @name dInputAutocompleteMixin
 * @prop {String} itemValue items property value key - default is 'value' - *optional*
 * @prop {String} itemText items property text key, the text that is displayed to the user - default is 'text' - *optional*
 * @prop {String} label the i18next translation key for the label - *optional*
 * @prop {Boolean} clearable the input gets an `X` button to clear its content - default is false - *optional*
 * @prop {Boolean} filled the input gets vuetify style filled - default is false - *optional*
 * @prop {String} appendIcon Appends an icon to the component, uses the same syntax as `v-icon`- *optional*
 * @prop {String} prependInnerIcon prepends an icon inside the component's input, uses the same syntax as `v-icon`- *optional*
 *
 * ```js
 * export default {
 *   mixins: [dInputAutocompleteMixin],
 *   methods: {
 *      // required method used from mixins `searchMethod`
 *      searchCallback(term) {
 *         return entityService.search(term)
 *      }
 *   }
 * }
 * ```
 */
const dInputAutocompleteMixin = {
  i18nOptions: { namespaces: ['common', 'activerecord'] },
  props: {
    filled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    noDataText: { type: String, default: null },
    appendIcon: { type: String, default: null },
    prependInnerIcon: { type: String, default: null },
    initialItems: { type: Array, default: () => [] },
    debounce: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      loading: false,
      items: this.initialItems,
      search: '',
      selected: '',
      timeout: null,
      initialSearch: true,
    }
  },
  computed: {
    noDataTextTranslated() {
      if (this.noDataText) {
        return this.noDataText
      }
      if (!this.label) {
        return null
      }
      return this.$t('autocomplete.noData', {
        searchType: this.$t(this.label),
        interpolation: { escapeValue: false },
      })
    },
  },
  watch: {
    search(term) {
      if (!this.initialSearch || this.initialItems.length === 0) {
        this.executeSearch(term || '')
      }
      this.initialSearch = false
    },
  },
  methods: {
    executeSearch(term) {
      clearTimeout(this.timeout)
      this.loading = true

      this.timeout = setTimeout(() => {
        this.searchCallback(term)
          .then((result) => {
            this.items = result
          })
          .finally(() => {
            this.loading = false
          })
      }, this.debounce)
    },
    resetItems() {
      this.items = this.initialItems
    },
  },
}

export default dInputAutocompleteMixin
