
<tr
  class="d-data-table-row"
  :class="getRowClass"
  @click="handleRowClick(item)"
>
  <td
    v-for="(header, index) in headers"
    :key="index"
    :class="getCellClasses(header)"
  >
    <slot :item="item" :name="columnName(header)">
      <div :style="alignCellValue(header)">
        {{ getValue(item, header) }}
      </div>
    </slot>
  </td>
</tr>
