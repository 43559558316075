import axios from 'axios'

const csrfHeader = () => {
  const token = document.querySelector('meta[name=csrf-token]')

  if (token) {
    return { 'X-CSRF-Token': token.getAttribute('content') }
  }
  return {}
}

const instance = axios.create({
  headers: {
    withCredentials: true,
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    ...csrfHeader(),
  },
})

const extractData = (response) => response.data || response

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // Axios GraphQL requests always return 200 even when eg. authentication fails
    // Therefore we need to check for errors inside the message
    // This is only the case for Axios, not for Apolo-Client
    if (
      response &&
      response.config &&
      response.config.url.match(/graphql/) &&
      response.data.data == null &&
      response.data.errors &&
      response.data.errors.find(
        (error) =>
          error.message &&
          error.message == 'You need to authenticate to perform this action'
      )
    ) {
      return Promise.reject(new Error('GraphQL Error: Unauthorized'))
    }
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

export const get = async (url, config = {}) => {
  const response = await instance.get(url, config)
  return extractData(response)
}

export const post = async (url, data, config = {}) => {
  const response = await instance.post(url, data, config)
  return extractData(response)
}

export const put = async (url, data, config = {}) => {
  const response = await instance.put(url, data, config)
  return extractData(response)
}

export const patch = async (url, data, { params = null, config = {} }) => {
  const response = await instance.patch(url, data, { params, ...config })
  return extractData(response)
}

export const destroy = async (url, config = {}) =>
  await instance.delete(url, config)

export const cancelToken = () => axios.CancelToken.source()

export default {
  get,
  post,
  create: post,
  put,
  update: put,
  patch,
  destroy,
  delete: destroy,
  cancelToken,
  csrfHeader,
}
