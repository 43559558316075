import Vue from 'vue'
import { dateTimeStr, timeStr } from '@/libs/dateTime'
import moment from 'moment'
import actionStateMutations from './actionStateMutations'

import { columnTypes, mutations as types } from '@/types/calendar'

export default {
  [types.SET_LOCALE_OPTION]: (
    state,
    locale = window.DenteoGlobals.localeDefault || 'de'
  ) => {
    Vue.set(state.options, 'locale', locale)
  },

  [types.SET_CALENDAR_NOW]: (state, now = null) => {
    const nowMoment = moment(now || undefined) // moment does not work with null
    Vue.set(state.options, 'now', dateTimeStr(nowMoment))
    Vue.set(state.options, 'nowTime', timeStr(nowMoment))
  },
  [types.SET_CALENDAR_FIRST_HOUR]: (state, firstHour) =>
    Vue.set(state.options, 'firstHour', firstHour),
  [types.SET_CALENDAR_LAST_HOUR]: (state, lastHour) =>
    Vue.set(state.options, 'lastHour', lastHour),
  // we need this because we modify the SET_CALENDAR_INTERVAL_HEIGHT in case of 0
  // this is used when rendering the calendar settings
  [types.SET_CALENDAR_INTERVAL_HEIGHT_SETTING]: (state, intervalHeight) =>
    Vue.set(state.options.interval, 'height_setting', intervalHeight),
  [types.SET_CALENDAR_INTERVAL_HEIGHT]: (state, intervalHeight) =>
    Vue.set(
      state.options.interval,
      'height',
      String(intervalHeight) === '0' ? '60' : intervalHeight
    ),
  [types.SET_CALENDAR_INTERVAL_MINUTES]: (state, intervalMinutes) =>
    Vue.set(state.options.interval, 'minutes', intervalMinutes),
  [types.SET_CALENDAR_INTERVAL_COUNT]: (state, count) =>
    Vue.set(state.options.interval, 'count', count),
  [types.SET_CALENDAR_FIRST_INTERVAL]: (state, interval) =>
    Vue.set(state.options.interval, 'first', interval),

  [types.SET_COLUMN_TYPE]: (state, columnType = null) =>
    Vue.set(
      state.columns,
      'type',
      columnTypes[columnType] || columnTypes.people
    ),
  [types.SET_COLUMN_TYPE_SELECTION]: (state, selection) => {
    Vue.set(state.columns, state.columns.type, selection)
  },

  [types.SET_CALENDAR_RESOURCES]: (state, calendarResources) =>
    Vue.set(state, 'calendarResources', calendarResources),
  [types.SET_CALENDAR_ROOMS]: (state, calendarRooms) =>
    Vue.set(state, 'calendarRooms', calendarRooms),
  [types.SET_EVENT_TYPES]: (state, eventTypes) =>
    Vue.set(state, 'eventTypes', eventTypes),
  [types.SET_BUSINESS_HOURS]: (state, businessHours) =>
    Vue.set(state, 'businessHours', businessHours),
  [types.SET_PHYSICIANS]: (state, physicians) => {
    Vue.set(state, 'physicians', physicians)
  },
  [types.MERGE_CALENDAR_OPTIONS]: (state, options) => {
    Vue.set(state, 'options', { ...state.options, ...options })
  },
  [types.SET_EVENTS]: (state, { events = [], mode, kind }) => {
    Vue.set(state.events[mode], kind, events)
  },
  [types.SET_EVENT_REMINDER_EMAIL_BODY]: (state, email) => {
    Vue.set(state.reminderContents, 'email', email)
  },
  [types.SET_EVENT_REMINDER_SUBJECT]: (state, subject) => {
    Vue.set(state.reminderContents, 'subject', subject)
  },
  [types.SET_EVENT_REMINDER_SMS_BODY]: (state, sms) => {
    Vue.set(state.reminderContents, 'sms', sms)
  },
  [types.FLUSH_EVENT_REMINDER_BODIES]: (
    state,
    { sms = null, email = null, subject = null } = {}
  ) =>
    Vue.set(state, 'reminderContents', {
      sms,
      email,
      subject,
    }),

  [types.SET_CALENDAR_SNAKBAR]: (
    state,
    { active = false, action = null } = {}
  ) => Vue.set(state.actionStates, 'snackbar', { active, action }),

  ...actionStateMutations,
  SET_MODE: (state, mode) => (state.mode = mode),
}
