
<v-textarea
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  v-on="{ ...$listeners, ...defaultHandlers }"
>
  <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

  <template v-for="(_, slotName) in $scopedSlots" #[slotName]="slotData">
    <slot :name="slotName" v-bind="slotData" />
  </template>
</v-textarea>
