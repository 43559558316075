import * as actions from './actions'
import * as getters from './getters'
import {
  SET_TREATMENT_FILLING_ASSISTANT,
  SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT,
  SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT,
  SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT,
  SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT,
} from '../../mutations-types'

import {
  drainingTypes,
  preparationEtchingTypes,
  fillingTypes,
  fillingKinds,
} from '@/modules/mini-app/components/filling-assistant/mock-treatment'

const mutations = {
  [SET_TREATMENT_FILLING_ASSISTANT](state, value) {
    state.fillingAssistantRecord.form_values = value
  },
  [SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT](state, value) {
    state.selectedSurfaceAreas = value
  },
  [SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT](state, value) {
    state.fillingAssistantRecord = value
  },
  [SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT](state, value) {
    state.mostUsedConfigLookupTable = value
  },
  [SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT](state, value) {
    state.prefillButtonHasBeenClicked = value
  },
}

const state = () => ({
  selectedSurfaceAreas: [],
  drainingTypes: drainingTypes,
  preparationEtchingTypes: preparationEtchingTypes,
  fillingTypes: fillingTypes,
  fillingKinds: fillingKinds,
  fillingAssistantRecord: null,
  mostUsedConfigLookupTable: null,
  // TODO:
  // This is only needed for an intermediate step
  // Once, we add a dirty mechanism to the form inputs, this property should be deleted again
  prefillButtonHasBeenClicked: false,
})

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
}
