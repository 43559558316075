
<v-radio-group
  v-model="model"
  :mandatory="required"
  v-bind="defaultInputBindings"
  v-on="defaultHandlers"
>
  <v-radio
    v-for="item in items"
    :key="item.value"
    :label="$t(item.text)"
    :value="item.value"
  />
</v-radio-group>
