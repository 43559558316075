
<fragment>
  <d-input-text
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    :class="['input-text-end', { 'disable-buttons': !enableButtons }]"
    type="number"
    v-on="defaultHandlers"
  />
</fragment>
