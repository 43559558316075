
<v-autocomplete
  ref="autocomplete"
  v-model="model"
  v-bind="defaultInputBindings"
  :class="cssClassName"
  :loading="isLoading"
  :items="filterItems"
  :prepend-inner-icon="prependInnerIcon"
  :no-data-text="noDataTextTranslated"
  :search-input.sync="query"
  auto-select-first
  item-text="label"
  item-value="poly_id"
  no-filter
  :background-color="backgroundColor"
  v-on="defaultHandlers"
  @keydown.native.enter="handleEnterKey"
>
  <template #item="{ item }">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <v-list-item-title v-html="highlight(item.label)" />
  </template>
</v-autocomplete>
