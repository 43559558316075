import { get } from '@/libs/axiosWrapper'

class Guarantor {
  async search(kind = 'undefined', term = '', params = {}) {
    return get(`/auto_complete/guarantors/${kind}`, {
      params: { ...params, term },
    })
  }
}

export default new Guarantor()
