<template>
  <div class="search-overlay">
    <button
      class="search-overlay__btn"
      role="button"
      @click.prevent="initSearchHandler"
    >
      <svg class="navigation__icon" focusable="false">
        <use xlink:href="#icon-search" />
      </svg>
    </button>

    <transition name="trs-fade-out" mode="in-out">
      <div v-if="isActive" class="search-overlay__body">
        <div class="search-overlay__overlay" @click="closeSearchHandler" />
        <div class="search-overlay__content">
          <quick-command :is-active="isActive" @cancel="closeSearchHandler" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import quickCommand from '../quick-command/quick-command.vue'
export default {
  components: {
    quickCommand,
  },
  data() {
    return {
      isActive: false,
    }
  },
  created() {
    document.addEventListener('keydown', this.keyboardKeysHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyboardKeysHandler)
  },
  methods: {
    initSearchHandler() {
      this.isActive = true
    },
    closeSearchHandler() {
      this.isActive = false
    },
    keyboardKeysHandler(event) {
      if (event.key === 'Escape') this.closeSearchHandler()
      if (event.key === 'k' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault()
        this.initSearchHandler()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .search-overlay__content {
    @media (max-width: 600px) {
      .v-menu__content {
        min-width: 80vw !important;
      }
    }
  }
}
.search-overlay {
  // Module
  & {
    .search-overlay__btn {
      width: 64px;
      height: 64px;
      border-radius: var(--nav-item-border-radius);
      background-color: var(--c-white);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--nav-box-shadow);
      margin-bottom: var(--nav-padding);
    }

    .search-overlay__body {
      position: relative;
      z-index: 1;
    }

    .search-overlay__overlay {
      background-color: rgb(33, 33, 33);
      border-color: rgb(33, 33, 33);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.86;
    }

    .search-overlay__content {
      position: fixed;
      top: 0;
      left: 50%;
      width: 640px;
      transform: translateX(-50%);

      @media (max-width: 600px) {
        width: 80vw;
      }
    }
  }
}
</style>
