<template>
  <v-tab ripple>{{ $t(`tabs.${tab}`) }}</v-tab>
</template>

<script>
export default {
  props: {
    tab: String,
  },
}
</script>
