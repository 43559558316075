
<v-autocomplete
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs, noDataText, ...clearable }"
  :search-input.sync="search"
  autocomplete="off"
  :loading="isLoading"
  :items="items"
  v-on="defaultHandlers"
>
</v-autocomplete>
