
<li v-if="canAccess" class="navigation__item navigation__item--inner">
  <a
    :href="pageUrl()"
    :class="[
      'navigation__link',
      { 'navigation__link--active': isLinkActive() },
    ]"
    :data-method="dataMethod"
    :rel="rel"
    :target="isExternal() ? '_blank' : null"
  >
    {{ $t(label) }}
  </a>
</li>
