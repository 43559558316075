import EmptyRouterView from '@/modules/core/layouts/empty-router-view.vue'
import FeeCatalogItemTable from './components/fee-catalog-item-table.vue'

export const feeCatalogBrowserRoutes = {
  name: 'feeCatalogBrowser',
  path: '/fee-catalogs',
  component: EmptyRouterView,
  children: [
    {
      path: '/:value',
      component: FeeCatalogItemTable,
      props: true,
    },
  ],
}
