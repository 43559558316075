<template>
  <v-checkbox
    v-model="checkbox"
    :label="label"
    class="px-6"
    @click="$emit('check', checkbox)"
  >
  </v-checkbox>
</template>
<script lang="ts">
import { ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  label: {
    type: String
  },
  isEnabled: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const checkbox = ref(props.isEnabled);
  return {
    checkbox
  };
};
export default __sfc_main;
</script>
