<template>
  <fragment>
    <d-input-text
      v-model="model"
      v-bind="{ ...defaultInputBindings, ...$attrs }"
      :class="['input-text-end', { 'disable-buttons': !enableButtons }]"
      type="number"
      v-on="defaultHandlers"
    />
  </fragment>
</template>

<script>
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
  props: {
    enableButtons: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.input-text-end ::v-deep input {
  text-align: end;
}

::v-deep.disable-buttons {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: none;
  }
}
</style>
