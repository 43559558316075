
<v-text-field
  v-model.trim="model"
  v-bind="{ ...defaultInputBindings, ...$attrs }"
  :type="showPassword ? 'text' : 'password'"
  v-on="defaultHandlers"
>
  <template #append>
    <button type="button" tabindex="-1" @click="showPassword = !showPassword">
      <v-icon v-if="showPassword">mdi-eye</v-icon>
      <v-icon v-if="!showPassword">mdi-eye-off</v-icon>
    </button>
  </template>
</v-text-field>
