import menPlaceholder from './images/men.svg'
import womenPlaceholder from './images/women.svg'
import girlPlaceholder from './images/girl.svg'
import boyPlaceholder from './images/boy.svg'
import unknownPlaceholder from './images/unknown.svg'

export const filterByLocationVisibility = (items, locationId) => {
  if (!locationId) {
    // This is a fallback for pages that are not location aware
    // We can deduce that an item (usually physician) should be visible
    // if it is displayed in at least in one calendar
    return items.filter((item) => item.display_in_calendars.length >= 1)
  } else {
    return items.filter(
      (item) => item.display_in_calendars.indexOf(locationId) !== -1
    )
  }
}

export const filterByLocationId = (items, locationId) => {
  return items.filter((item) => item && item.location_id === locationId)
}

export const defaultAvatarForPerson = (
  { age, sex },
  avatarsForKids = false
) => {
  let avatar
  switch (sex) {
    case 'm':
      avatar =
        avatarsForKids && typeof age === 'number' && age < 17 ? boyPlaceholder : menPlaceholder
      break
    case 'f':
      avatar =
        avatarsForKids && typeof age === 'number' && age < 17 ? girlPlaceholder : womenPlaceholder
      break
    default:
      avatar = unknownPlaceholder
  }
  return 'data:image/svg+xml;base64,' + btoa(avatar)
}
