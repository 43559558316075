<template>
  <div class="$attrs.class">
    <v-menu
      :key="datePickerKey"
      v-model="pickerVisible"
      offset-y
      nudge-bottom="1"
      transition="fade-transition"
      min-width="290px"
      :close-on-click="closeable"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-text-field
          ref="textField"
          class="text-input"
          :class="{ 'inactive-text-color': inactive }"
          v-bind="{ ...$attrs, ...defaultInputBindings }"
          :value="text"
          v-on="{ ...on, ...defaultHandlers }"
          @input="setText"
          @blur="resetText"
          @click="$emit('click')"
        ></v-text-field>
      </template>

      <v-date-picker
        :value="model"
        no-title
        scrollable
        v-bind="$attrs"
        color="primary"
        :events="events"
        :locale="$i18n.i18next.language"
        first-day-of-week="1"
        @input="pickerChanged($event)"
        v-on="defaultHandlers"
      >
        <v-spacer></v-spacer>
        <v-btn text @click="closePicker">{{ $t('common:close') }}</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import dInputComponentMixin from './d-input-component-mixin'
export default {
  mixins: [dInputComponentMixin],
  inheritAttrs: false,
  props: {
    events: {
      type: Array,
      default: null,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerVisible: false,
      datePickerKey: 0, // Used to force v-date-picker to reset state when menu is closed
      closeable: true,
      text: this.formatDate(this.value),
    }
  },
  watch: {
    pickerVisible(value) {
      if (!value) {
        this.datePickerKey++
      }
    },
    model: {
      immediate: true,
      handler(value) {
        this.text = this.formatDate(value)
      },
    },
  },
  methods: {
    setText(value) {
      this.text = value
      this.vuelidate && this.vuelidate.$touch()
      const parsedDate = moment(value, 'DD.MM.YYYY', true)
      const parsedLazyDate = moment(value, 'D.M.YYYY', true)

      if (!value) {
        this.setModel(null)
      } else if (parsedDate.isValid()) {
        this.setModel(parsedDate.format('YYYY-MM-DD'))
      } else if (parsedLazyDate.isValid()) {
        setTimeout(() => {
          this.setModel(parsedLazyDate.format('YYYY-MM-DD'))
        }, 1200)
      }
    },
    setModel(date) {
      this.model = date
    },
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : null
    },
    resetText() {
      this.text = this.formatDate(this.model)
    },
    hidePicker(delay = 300) {
      // Use a timeout for closing the picker as it
      // feels more natural.
      setTimeout(() => (this.pickerVisible = false), delay)
    },
    pickerChanged(date) {
      this.model = date
      this.$refs.textField.focus()
      this.hidePicker()
    },

    closePicker() {
      this.$refs.textField.focus()
      this.hidePicker(0)
    },
  },
}
</script>
<style lang="scss" scoped>
.inactive-text-color {
  ::v-deep input {
    color: rgba(0, 0, 0, 0.37) !important;
  }
}
.text-input {
  ::v-deep input {
    cursor: pointer;
  }
}
</style>
