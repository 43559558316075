import compact from 'lodash/compact'

export const q1 = Object.freeze([18, 17, 16, 15, 14, 13, 12, 11])
export const q2 = Object.freeze([21, 22, 23, 24, 25, 26, 27, 28])
export const q3 = Object.freeze([31, 32, 33, 34, 35, 36, 37, 38])
export const q4 = Object.freeze([48, 47, 46, 45, 44, 43, 42, 41])

export const childrenQ1 = Object.freeze([55, 54, 53, 52, 51])
export const childrenQ2 = Object.freeze([61, 62, 63, 64, 65])
export const childrenQ3 = Object.freeze([71, 72, 73, 74, 75])
export const childrenQ4 = Object.freeze([85, 84, 83, 82, 81])

export const maxila = Object.freeze([...q1, ...q2])
export const mandibula = Object.freeze([...q4, ...q3])

export const all = Object.freeze([
  ...q1,
  ...q2,
  ...q3,
  ...q4,
  ...childrenQ1,
  ...childrenQ2,
  ...childrenQ3,
  ...childrenQ4,
])

export const quadrantPositionsFromTeeth = (teeth) => {
  const schema = [1, 2, 3, 4].reduce((schema, quadrant) => {
    schema[`q${quadrant}`] = [8, 7, 6, 5, 4, 3, 2, 1].map((position) => {
      const permanent = quadrant * 10 + position
      const child = permanent + 40

      if (teeth.indexOf(permanent) !== -1) {
        return permanent
      } else if (teeth.indexOf(child) !== -1) {
        return child
      } else {
        return null
      }
    })
    return schema
  }, {})

  return {
    q1: schema.q1,
    q2: schema.q2.reverse(),
    q3: schema.q3.reverse(),
    q4: schema.q4,
  }
}

export const getQuadrantPositionsFromTeethWithMissing = (teeth) => {
  const schema = [1, 2, 3, 4].reduce((schema, quadrant) => {
    schema[`q${quadrant}`] = [8, 7, 6, 5, 4, 3, 2, 1].map((position) => {
      const permanent = quadrant * 10 + position
      const child = permanent + 40

      return {
        nb: teeth.indexOf(child) !== -1 ? child : permanent,
        missing: teeth.indexOf(permanent) === -1 && teeth.indexOf(child) === -1,
      }
    })
    return schema
  }, {})

  return {
    q1: schema.q1,
    q2: schema.q2,
    q3: schema.q3,
    q4: schema.q4,
  }
}

export const teethFromQuadrantPositions = (schema) =>
  compact([...schema.q1, ...schema.q2, ...schema.q3, ...schema.q4])

export default {
  q1,
  q2,
  q3,
  q4,
  maxila,
  mandibula,
  all,
}
