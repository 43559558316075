
<v-select
  v-model="model"
  v-bind="{ ...defaultInputBindings, ...$attrs, noDataText }"
  :items="items"
  :menu-props="menuProps"
  :item-text="itemText"
  :item-value="itemValue"
  v-on="defaultHandlers"
>
  <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"
    ><slot :name="slot" v-bind="scope"
  /></template>
</v-select>
