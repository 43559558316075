
<div>
  <v-row>
    <v-col>
      <v-label>
        {{ translatedLabel }}
      </v-label>
    </v-col>
  </v-row>
  <v-row>
    <v-color-picker
      v-model="model"
      :hide-canvas="true"
      :hide-inputs="true"
      :hide-mode-switch="true"
      :hide-sliders="true"
      :show-swatches="true"
      :swatches="swatchesForInput"
      :mode="mode"
      width="100%"
      v-bind="{ ...defaultInputBindings, ...$attrs }"
      v-on="defaultHandlers"
    />
  </v-row>
</div>
