<template>
  <v-autocomplete
    v-model="model"
    v-bind="{ ...defaultInputBindings, ...$attrs, noDataText, ...clearable }"
    :search-input.sync="search"
    autocomplete="off"
    :loading="isLoading"
    :items="items"
    v-on="defaultHandlers"
  >
  </v-autocomplete>
</template>

<script>
import autocompleteListService from '../../../services/autocomplete-list'
import dInputComponentMixin from './d-input-component-mixin'

export default {
  mixins: [dInputComponentMixin],
  props: {
    noDataText: {
      type: String,
      required: false,
      default() {
        return this.$t('noValues')
      },
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      items: [],
      isLoading: false,
    }
  },
  async created() {
    this.isLoading = true
    this.items = await autocompleteListService.fetchCountries()
    this.isLoading = false
  },
}
</script>
