<template>
  <div class="document-search">
    <d-autocomplete
      ref="documentSearch"
      v-model="model"
      v-click-outside="blur"
      :loading="isLoading"
      :search-input.sync="query"
      :items="documents"
      :menu-props="{ maxHeight, minWidth: '365px' }"
      filled
      item-text="label"
      item-value="id"
      :placeholder="placeholder || $t('patient_document_placeholder')"
      :hide-no-data="hideNoData"
      :no-data-text="$t('documents_empty')"
      append-icon=""
      color="#949494"
      height="56"
      class="document-search__input"
      background-color="#F0F0F0"
      clearable
      auto-select-first
      open-on-clear
      no-filter
      v-bind="{ ...defaultInputBindings, ...$attrs }"
      v-on="defaultHandlers"
      @keydown.esc="$emit('cancel')"
    >
      <template #item="{ item }">
        <div class="d-flex flex-column" style="z-index: 1">
          <div
            style="font-size: 16px; padding-bottom: 4px"
            v-html="highlight(item.patientFullName, { normalize: true })"
          />

          <div class="document-info" v-html="highlight(item.documentInfo)" />

          <div class="recipient" v-html="highlight(item.recipientPerson)" />
        </div>
      </template>
    </d-autocomplete>
  </div>
</template>

<script>
import clickOutside from '@/libs/directives/click-outside'
import { gql } from '@apollo/client/core'
import { query } from '@/libs/graphql'
import { numberToCurrency } from '@/libs/number-format'
import dInputComponentMixin from './d-input-component-mixin'
import { highlight } from '@/libs/highlight'
import DOCUMENT_AUTOCOMPLETE_FIELDS from '@/graphql/fragments/document_autocomplete_fields.js'

const DEFAULT_MAX_HEIGHT = 300
const MIN_QUERY_CHARACTERS = 2

export default {
  directives: {
    clickOutside,
  },
  mixins: [dInputComponentMixin],
  i18nOptions: { namespaces: ['activerecord', 'common', 'autocomplete'] },
  props: {
    // shows default items in the dropdown that are displayed when query is empty
    autoSuggest: {
      type: Boolean,
      default: false,
    },
    autoSuggestedItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      documents: [],
      query: null,
      queryWords: [],
      maxHeight: 0,
      hideNoData: true,
      suggestedItems: [],
    }
  },
  watch: {
    autoSuggestedItems: {
      immediate: true,
      handler() {
        if (this.autoSuggestedItems) {
          this.suggestedItems = this.transformDocuments(this.autoSuggestedItems)
          this.documents = this.suggestedItems
        }
      },
    },
    query(newVal, oldVal) {
      newVal &&
        newVal !== oldVal &&
        newVal.length >= MIN_QUERY_CHARACTERS &&
        this.runQuery(newVal)

      if (!newVal) {
        this.model = null
        this.hideNoData = true
        this.documents = this.suggestedItems
      } else {
        this.queryWords = newVal.split(' ').filter((word) => word.length > 1)
        this.hideNoData = false
      }
    },
  },
  async mounted() {
    if (this.autoSuggest && this.autoSuggestedItems) {
      this.suggestedItems = this.transformDocuments(this.autoSuggestedItems)
      this.documents = this.suggestedItems
    }

    // for some reason $el invisibly moves
    setTimeout(() => {
      const maxHeight =
        window.innerHeight -
        this.$refs.documentSearch.$el.getBoundingClientRect().bottom
      this.maxHeight =
        maxHeight > DEFAULT_MAX_HEIGHT ? `${DEFAULT_MAX_HEIGHT}px` : maxHeight
    }, 500)
  },
  methods: {
    transformDocuments(gqlDocuments) {
      return gqlDocuments.map(
        ({
          id,
          patient: { displayName },
          totalPrice,
          type,
          createdAt,
          recipientPerson,
          recipient,
        }) => ({
          id,
          label: [
            displayName,
            [this.$t(`activerecord:models.${type.toLowerCase()}`), id].join(
              ' '
            ),
            numberToCurrency(totalPrice),
          ].join(', '),
          patientFullName: displayName,
          documentInfo: [
            numberToCurrency(totalPrice),
            this.$l(createdAt),
            [this.$t(`activerecord:models.${type.toLowerCase()}`), id].join(
              ' '
            ),
          ].join(', '),
          recipientPerson: recipientPerson
            ? `${recipientPerson.fullname}, ${recipientPerson.addressShort}`
            : recipient,
        })
      )
    },
    async runQuery(search) {
      this.isLoading = true
      try {
        const {
          data: {
            patientDocumentsAutocomplete: { nodes: documentsList },
          },
        } = await query({
          query: gql`
            ${DOCUMENT_AUTOCOMPLETE_FIELDS}
            query patientDocumentsAutocomplete($search: String!) {
              patientDocumentsAutocomplete(search: $search) {
                nodes {
                  ...DocumentAutocompleteFields
                }
              }
            }
          `,
          variables: {
            search,
          },
        })
        this.documents = this.transformDocuments(documentsList)
      } catch (err) {
        console.error(err)
      } finally {
        this.isLoading = false
      }
    },
    highlight(text, options) {
      return highlight(text, this.queryWords, {
        ...options,
        wrapperClass: 'highlighter--underline',
      })
    },
    blur() {
      this.$refs.documentSearch.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.document-search {
  // Module
  & {
    z-index: 1000;
    display: flex;
    justify-content: center;

    .document-search__input {
      max-width: 740px !important;
      border-radius: 4px 4px 0 0;
    }

    .document-info {
      font-size: 14px;
    }
    .recipient {
      padding-top: 4px;
      font-size: 14px;
    }

    ::v-deep {
      .v-list {
        padding: 0;
      }

      .v-select__slot {
        .v-input__append-inner {
          margin-top: 2px !important;
        }
      }

      .v-list-item {
        padding: 16px;
        &::after {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          width: 100%;
          flex: 1 1 100%;
          position: absolute;
          bottom: 0px;
          left: 0;
        }
      }

      .v-list-item__content {
        position: relative;
      }

      .v-list-item__title {
        font-size: 16px;
        line-height: 20px !important;
      }

      .v-select__slot > input {
        font-size: 15px;
        line-height: 24px;
      }

      .v-list-item--link {
        opacity: 1 !important;
        background-color: transparent;
        &:before {
          background-color: transparent;
        }
      }

      .v-autocomplete__content.v-menu__content {
        border-radius: 0 0 8px 8px !important;
        max-width: none !important;
        width: 100%;
        margin-top: 2px;
      }

      .v-input__control > .v-input__slot {
        padding: 16px !important;
        font-size: 15px;
      }

      .v-list-item--highlighted {
        background-color: transparent;
        &:before {
          opacity: 1 !important;
          background-color: #f3f3f3;
        }
      }
    }
  }

  // State
  & {
    ::v-deep {
      .v-list-item:hover {
        transition: background-color 0.2s ease-in-out;
        background-color: var(--v-primary-base);
      }

      .v-select__slot > input::placeholder {
        opacity: 1;
      }
    }
  }
}
</style>
