<template>
  <v-dialog
    v-model="dialogState"
    fullscreen
    hide-overlay
    eager
    transition="dialog-bottom-transition"
  >
    <div class="mini-app" :class="`mini-app-${title}`">
      <div
        class="mini-app__container"
        :style="`max-width: ${maxContentWidth}px`"
      >
        <mini-app-header :title="title" :custom-header="customHeader" />
        <div class="mini-app__scroll-container">
          <slot></slot>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import miniAppHeader from './components/header.vue'

export default {
  components: {
    miniAppHeader,
  },
  provide() {
    return {
      launcher: this,
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    title: { type: String, required: true },
    maxContentWidth: { type: Number, required: false, default: 1680 },
    customHeader: { type: String, default: null },
  },
  data() {
    return {
      lastSavedTimestamp: null,
    }
  },
  computed: {
    lastSaved: {
      get() {
        return this.lastSavedTimestamp
      },
      set(val) {
        this.lastSavedTimestamp = val
        setTimeout(() => (this.lastSavedTimestamp = null), 3000)
      },
    },
    dialogState: {
      get() {
        return this.isOpen
      },
      set() {
        this.close()
      },
    },
  },
  beforeMount() {
    document.body.classList.add('no-scroll')
  },
  beforeDestroy() {
    document.body.classList.remove('no-scroll')
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.mini-app {
  min-height: 100vh;
  background: #fafafa;

  &__container {
    width: 100%;
    margin: 0 auto;
  }

  &__scroll-container {
    padding: 12px 48px;
    overflow: auto;
  }
}

.mini-app.mini-app-filling_assistant .mini-app__scroll-container {
  padding: 12px 40px;
}

::v-deep .v-dialog--fullscreen {
  overflow-y: scroll !important;
}
</style>
