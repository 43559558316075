<template>
  <div class="d-layout">
    <aside class="d-layout__sidebar">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        class="d-layout__sidebar-link"
        :to="route.path"
      >
        {{ route.rawLabel ? route.label : $t(route.label) }}
      </router-link>
    </aside>
    <main class="d-layout__container">
      <router-view />
    </main>
  </div>
</template>

<script>
export default {
  name: 'DLayout',
  props: {
    routes: {
      description: `
        [
          { path: '/route/child-route', label: 'String', rawLabel: true },
          { path: '/route/child-route2', label: 'i18n_key2' },
        ]
      `,
      type: Array,
      required: true,
    },
  },
  watch: {
    routes: {
      immediate: true,
      handler() {
        this.redirectToFirstRoute()
      },
    },
    $route: {
      immediate: true,
      handler() {
        this.redirectToFirstRoute()
      },
    },
  },
  methods: {
    redirectToFirstRoute() {
      if (Array.isArray(this.routes) && this.routes.length) {
        const { value } = this.$route.params
        // If we change the route to rails route and go back to vue router
        // we want to go to the last route that we visited
        if (value) {
          this.$router.push(this.$route.fullPath, () => ({}))
          return
        }

        // Check if the current `value` is a existing route if not
        // then we redirect the user to the first path or 404 page?
        const isExistingRoute = this.routes.some((el) =>
          el.path.includes(value)
        )

        // Check if the route === '/' then we have to redirect the user
        // to the first route
        if (this.$route.path === '/' || !isExistingRoute) {
          this.$router.push(this.routes[0]?.path, () => ({}))
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.d-layout {
  // Vars

  // Support

  // Module
  & {
    display: grid;
    grid-template-columns: 224px 1fr;
    grid-gap: 40px;

    .d-layout__sidebar-link {
      height: 48px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #f0f0f0;
      margin-top: 8px;
    }
  }

  // Facets
  & {
    .d-layout__sidebar-link--active {
      background-color: var(--v-grey-20-base);
    }
  }

  // States
}
</style>
