import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import clinicalRecords from './modules/clinical-records'
import findings from './modules/findings'
import calendar from '../modules/calendar/store'
import twoFA from './modules/2FA'
import fillingAssistant from './modules/filling-assistant'
import sidepanel from './modules/sidepanel'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { calendar },
  strict: true,
})

store.registerModule('common', common)
store.registerModule('clinicalRecords', clinicalRecords)
store.registerModule('fillingAssistant', fillingAssistant)
store.registerModule('findings', findings)
store.registerModule('twoFA', twoFA)
store.registerModule('sidepanel', sidepanel)

export default store
