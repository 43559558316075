import findingService from '../../../services/finding'
import { ToothSelector } from '../../../modules/tooth-schema/teeth'

const initialState = () => ({
  findings: [],
  patientId: null,
})

const actions = {
  async loadFindings({ commit, state: { patientId } }) {
    const { findings } = await findingService(patientId).fetchAll()

    commit('setFindings', findings)
  },
}

const getters = {
  getFindings: ({ findings }) => findings,
  getFindingsForTeeth:
    (_, { getFindings }) =>
    (toothSelection) => {
      if (!toothSelection) return []

      const toothSelectionNumbers = new ToothSelector(toothSelection).teeth.map(
        ({ number }) => parseInt(number)
      )

      return getFindings
        .map((finding) => {
          const findingToothNumbers = finding.toothSelector.split(', ')

          const containedTeeth = findingToothNumbers.filter(
            (findingToothNumber) =>
              toothSelectionNumbers.includes(parseInt(findingToothNumber))
          )

          if (containedTeeth.length === 0) return false

          return {
            ...finding,
            toothSelector: containedTeeth.join(', '),
          }
        })
        .filter(Boolean)
    },
}

const mutations = {
  setPatientId(state, value) {
    state.patientId = value
  },
  setFindings(state, value) {
    state.findings = value
  },
}

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters,
  mutations,
}
