import { gql } from '@apollo/client/core'
import { useMutation, useQuery } from '@vue/apollo-composable'

let isMounted = false

type NewsItem = {
  id: string
  title: string
  body: string
  url: string
  release_on: string
  language: string
  beta: boolean
}

/**
 * Custom composable for showing news items.
 *
 * @param options - The options for the hook.
 * @param options.url - The URL to fetch news items from.
 * @param options.limit - The maximum number of news items to display.
 * @returns An object containing the `onNewsAvailable` function.
 */
export function useNews(options: { url: string; limit: number }) {
  const { mutate: usersMarkNewsAsRead } = useMutation(gql`
    mutation MarkNewsAsRead($newsReadUntil: ISO8601Date!) {
      usersMarkNewsAsRead(date: $newsReadUntil) {
        date
      }
    }
  `)

  const { refetch } = useQuery(gql`
    query UsersNewsSettings {
      usersNewsSettings {
        from
        to
        language
        readUntil
        loggedInAt
      }
    }
  `)

  const trackAsSeen = async (news: NewsItem) => {
    console.debug('User has seen the news item on', news.release_on)
    return usersMarkNewsAsRead({ newsReadUntil: news.release_on })
  }

  const fetchNews = async (url: string, from: string, to: string) => {
    try {
      const response = await fetch(`${url}?from=${from}&until=${to}`)
      const data = await response.json()
      return data.news_items
    } catch (error) {
      console.error('Could not fetch news items,', error)
      return []
    }
  }

  const isNewsRelevant = (
    newsItem: NewsItem,
    releasedFrom: number,
    releasedUntil: number,
    language: string
  ) =>
    // Check if the news item is in the user's language
    newsItem.language == language &&
    // return only news which were released before the practice's cut off date
    // and after the instance was established
    Date.parse(newsItem.release_on) >= releasedFrom &&
    Date.parse(newsItem.release_on) <= releasedUntil

  /**
   * This function can be called when a Vue-app is mounted. It will fetch news items from the provided URL
   * and the callback will be invoked withe the oldest unseen news item.
   *
   * @param callback - The callback function to be called with the oldest news item that the user has not seen yet and a tracking function to mark the passed news as read.
   */
  const onNewsAvailable = async (
    callback: (news: NewsItem, trackingFunction: () => void) => void
  ) => {
    // News can be disabled with environment variable
    if (!window.DenteoGlobals.newsEnabled) return

    // Multiple vue apps can be mounted on the same page
    // This is a way to prevent the fetch from running multiple times
    if (isMounted) return
    isMounted = true

    const {
      data: { usersNewsSettings },
    } = await refetch()

    const timeAfterLogin = 10000
    // We only want to show news items to users who have logged in recently
    if (
      Date.now() - Date.parse(usersNewsSettings.loggedInAt) >
      timeAfterLogin
    ) {
      console.debug(
        'Skipping news, user has been logged in for more than 10 seconds'
      )
      return
    }

    const news = await fetchNews(
      options.url,
      usersNewsSettings.from,
      usersNewsSettings.to
    )

    displayNextNewsItem(
      news,
      Date.parse(usersNewsSettings.readUntil),
      Date.parse(usersNewsSettings.from),
      Date.parse(usersNewsSettings.to),
      usersNewsSettings.language,
      callback
    )
  }

  /**
   * Displays the next news item to the user based on the provided criteria.
   * Calls the provided callback function with the oldest news item that the user has not read yet.
   *
   * After the user has seen the news item, the passed `trackingFunction` function should be called,
   * to track the news item as read and to display the next news item.
   *
   * @param newsItems - An array of news items. Expted to be sorted by release date, newest first, oldest last.
   * @param readUntil - The timestamp indicating until when the user has read news items.
   * @param releasedFrom - The timestamp indicating the start date for considering news items.
   * @param releasedUntil - The timestamp indicating the end date for considering news items.
   * @param language - The language preference of the user, news in other languages will be skipped.
   * @param callback - The callback function to be called with the oldest news item that the user has not seen yet and a tracking function to mark the passed news as read.
   */
  const displayNextNewsItem = (
    newsItems: NewsItem[],
    readUntil: number,
    releasedFrom: number,
    releasedUntil: number,
    language: string,
    callback: (news: NewsItem, trackingFunction: () => void) => void
  ) => {
    const candidatesToShow = newsItems.filter(
      (newsItem: NewsItem) =>
        // Check if the user has not seen the news item
        Number(readUntil) < Date.parse(newsItem.release_on) && isNewsRelevant(newsItem, releasedFrom, releasedUntil, language)
    )

    if (candidatesToShow.length > 0) {
      const oldestNewsItem = candidatesToShow[candidatesToShow.length - 1]

      callback(
        oldestNewsItem,
        async function trackAsSeenAndDisplayNextNewsItem() {
          await trackAsSeen(oldestNewsItem)

          displayNextNewsItem(
            newsItems,
            Date.parse(oldestNewsItem.release_on),
            releasedFrom,
            releasedUntil,
            language,
            callback
          )
        }
      )
    }
  }

  const load = async () => {
    const {
      data: { usersNewsSettings },
    } = await refetch()

    const news = await fetchNews(
      options.url,
      usersNewsSettings.from,
      usersNewsSettings.to
    )

    return news.filter((newsItem: NewsItem) =>
      isNewsRelevant(
        newsItem,
        Date.parse(usersNewsSettings.from),
        Date.parse(usersNewsSettings.to),
        usersNewsSettings.language
      )
    )
  }

  return {
    onNewsAvailable,
    load,
  }
}
