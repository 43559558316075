
<li class="navigation__item navigation__item-has-children">
  <transition-group tag="ul" class="navigation__inner" name="trs-list">
    <li
      v-for="patient in patients"
      :key="patient.id"
      class="navigation__item navigation__item--profile"
      :class="{ 'navigation__item--profile--active': isActive(patient.id) }"
    >
      <a :href="getActiveURLIfExist(patient.id)" class="navigation__link">
        {{ patient.initials }}
      </a>
    </li>
  </transition-group>
</li>
