// Draining/Trockenlegung
export const drainingTypes = ['relative_draining', 'absolute_draining']

// Preparation/Vorbereitung
export const preparationEtchingTypes = [
  'etch_rinse',
  'selective_etch',
  'self_etch',
]

// Filling/Füllung
export const fillingTypes = ['provisory_filling', 'definitive_filling']
export const fillingKinds = [
  'amalgam',
  'composite',
  'glasionomerzement',
  'alkasite',
]

// Note: Any changes to the structure of this data may require adjustments of the queries defined in app/services/assistant_config_builders/filling.rb
// TODO: should we persist products: 'null' instead of products: '[{"name": null, "quantity": null, ...}]'?
export const emptyTreatment = {
  tooth: {
    config: { separator: '', avoidLocalisation: true, position: 1 },
    number: '', // e.g. '81'
    surfaceAreas: '', // e.g. ild
  },
  selectedFindings: {
    config: { hideInSummary: true },
    ids: [],
  },
  anesthesia: {
    config: { position: 2 },
    surface: {
      active: false,
      products: [{ name: null }],
    },
    infiltration: {
      active: false,
      products: [{ name: null, quantity: null }],
    },
    conduction: {
      active: false,
      products: [
        {
          name: null,
          quantity: null,
          localisation: null,
        },
      ],
    },
    intraligamentous: {
      active: false,
      products: [
        {
          name: null,
          quantity: null,
        },
      ],
    },
  },
  draining: {
    config: { position: 3 },
    type: drainingTypes[1],
    products: [{ name: null }],
  },
  preparation: {
    config: { position: 4 },
    isEdgeBevel: false,
    etch: preparationEtchingTypes[0],
    products: [{ name: null }],
  },
  filling: {
    config: { position: 5 },
    type: fillingTypes[1],
    properties: {
      kind: fillingKinds[1],
      products: [{ name: null, color: null }],
    },
  },
  closing: {
    config: { position: 6 },
    finishingpolishing: {
      active: false,
      products: [{ name: null }],
    },
    isClosingOcclusionArticulation: false,
    fluoridation: {
      active: false,
      products: [{ name: null }],
    },
  },
  freetext: {
    config: { avoidLocalisation: true, position: 7 },
    content: '',
  },
}
