<template>
  <!-- TODO: title should not be translated -->
  <d-section :title="title">
    <d-nested-table
      :headers="headers"
      enable-loading
      :loading="isLoading"
      :items="items"
      :no-data-text="$t('empty_table')"
    >
      <template #item.code="{ item }">
        <span class="text-no-wrap">
          {{ item.code }}
        </span>
      </template>
    </d-nested-table>
  </d-section>
</template>

<script>
import { query } from '@/libs/graphql'
import { gql } from '@apollo/client/core'
import compact from 'lodash/compact'

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  i18nOptions: {
    namespaces: ['fee_catalog', 'common'],
  },
  data() {
    return {
      isLoading: false,
      title: null,
      items: [],
    }
  },
  computed: {
    headers() {
      return compact([
        { text: 'headers.code', value: 'code' },
        { text: 'headers.title', value: 'title' },
      ])
    },
  },
  async mounted() {
    await this.fetchFeeCatalog()
  },
  methods: {
    async fetchFeeCatalog() {
      this.isLoading = true
      const { data, errors } = await query({
        query: gql`
          query feeCatalog($id: ID!) {
            feeCatalog(id: $id) {
              title
              feeItems {
                nodes {
                  id
                  code
                  remarks
                  title
                }
              }
            }
          }
        `,
        variables: {
          id: this.value,
        },
      })

      if (data !== null && errors === undefined) {
        const {
          title,
          feeItems: { nodes: items },
        } = data.feeCatalog

        this.title = title
        this.items = items

        this.isLoading = false
      }
    },
  },
}
</script>
