import RestEntity from './rest-entity'

class ClinicalRecord extends RestEntity {
  entityName = 'clinical_record'

  async findOrInitialize(obj = {}) {
    if (obj.id) {
      const { clinical_record } = await this.fetch(obj.id)
      return clinical_record
    } else {
      return obj
    }
  }

  defaultRecord(defaultValues = {}) {
    return {
      template_category: '0_general',
      template_name: '0_freitext',
      at: new Date().toISOString(),
      teeth: '',
      patient_id: null,
      created_by_person_id: null,
      form_values: { freetext: '' },
      ...defaultValues,
    }
  }

  defaultRecordParoStatus(defaultValues = {}) {
    return {
      template_category: '1_klinische_befunde',
      template_name: '2_parodontale_befundaufnahme',
      at: new Date(),
      // at: new Date().toISOString(),
      is_editable: true,
      form_values: { teeth: [] },
      ...defaultValues,
    }
  }

  defaultRecordHygiene(defaultValues = {}) {
    return {
      template_category: '1_klinische_befunde',
      template_name: '4_mundhygiene',
      at: new Date(),
      is_editable: true,
      ...defaultValues,
    }
  }

  defaultRecordDenture(appName, defaultValues = {}) {
    let templateName
    switch (appName) {
      case 'mobility':
        templateName = '7_mobilitaet'
        break
      case 'vitality':
        templateName = '8_vitalitaet'
        break
    }
    return {
      template_category: '1_klinische_befunde',
      template_name: templateName,
      at: new Date(),
      is_editable: true,
      ...defaultValues,
    }
  }

  defaultRecordFillingAssistant(defaultValues = {}) {
    return {
      template_category: '1_klinische_befunde',
      template_name: 'filling_assistant', // TODO: should we use integer prefix?
      at: new Date(),
      patient_id: null,
      teeth: null,
      physician_id: null,
      created_by_person_id: null,
      form_values: null,
      ...defaultValues,
    }
  }
}

export default new ClinicalRecord()
