<template>
  <tr
    class="d-data-table-row"
    :class="getRowClass"
    @click="handleRowClick(item)"
  >
    <td
      v-for="(header, index) in headers"
      :key="index"
      :class="getCellClasses(header)"
    >
      <slot :item="item" :name="columnName(header)">
        <div :style="alignCellValue(header)">
          {{ getValue(item, header) }}
        </div>
      </slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'DDataTableRow',
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Array,
      default: () => {
        return []
      },
    },
    rowClick: {
      type: Function,
      default: null,
    },
    dragging: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getRowClass() {
      return this.rowClick ? 'row-with-event' : ''
    },
  },
  methods: {
    columnName(header) {
      return `item.${header.value}`
    },
    alignCellValue(header) {
      const align = header.align ? header.align : 'left'
      return `text-align: ${align}`
    },
    getValue(item, header) {
      if (!header || !header.value) return
      const headerValues = header.value.split('.')
      let itemValue = item
      for (const prop of headerValues) {
        itemValue = itemValue[prop] ? itemValue[prop] : ''
      }
      return itemValue ? itemValue : ''
    },
    getCellClasses(header) {
      const classes = []
      header.value === 'select' && classes.push('select')
      header.align === 'end' && classes.push('text-right')
      header.value === 'drag' && classes.push('hand')
      return classes
    },
    handleRowClick(item) {
      if (this.dragging || !this.rowClick) {
        return
      }
      this.rowClick(item)
    },
  },
}
</script>
