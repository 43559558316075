import { gql } from '@apollo/client/core'

export default gql`
  fragment DocumentAutocompleteFields on Document {
    id
    totalPrice
    type
    patient {
      id
      displayName
    }
    recipient
    recipientPerson {
      id
      fullname
      address
      addressShort
    }
  }
`
