<template>
  <fragment>
    <navigation-patient
      :user-permissions="userPermissions"
      :patient="patient"
    />
    <navigation-profile :user-permissions="userPermissions" />
  </fragment>
</template>

<script>
import { gql } from '@apollo/client/core'
import { query } from '@/libs/graphql'
import patientIdParam from './services/patient-id-param'
import NavigationPatient from './navigation-patient.vue'
import NavigationProfile from './navigation-profile.vue'
import navigation from '@/js/navigation'

const NAV_FLYOUT_ACTIVE = 'navigation__flyout--active'
const NAV_FLYOUT_ACTIVE_WITH_OVERLAY = 'navigation__flyout--active-with-overlay'

export default {
  components: {
    NavigationPatient,
    NavigationProfile,
  },
  data() {
    return {
      patientId: null,
      patient: {},
      userPermissions: {
        permissions: { canInspect: true },
        dated_positions: false,
        clinical_records: false,
        prescriptions: false,
        journal_positions: false,
        invoices: false,
        bids: false,
        care_plans: false,
        events: false,
        patient_recalls: false,
        media_assets: false,
        profiles: false,
      },
    }
  },
  beforeDestroy() {
    // Turbolinks_permanent=true is required on the vue_app injector to avoid flickering on each reload
    // We can't rely on a prop @patient.id in the vue_app injector, as it wouldn't update on page change
    // We get the patient ID from the URL params
    // Inspired by navigation-patient-quick-links.vue
    document.removeEventListener('turbolinks:load', this.getNavInfo)
  },
  created() {
    document.addEventListener('turbolinks:load', this.getNavInfo)
  },
  methods: {
    async getPermissions() {
      this.patientId = parseInt(patientIdParam())

      const ACTION = 'manage'

      // Build patient query only if it's a patient's page
      let patientQuery = ''
      let queryVars = {}
      let queryParams = ''
      if (this.patientId) {
        queryVars = { id: this.patientId }
        queryParams = '($id: ID!)'
        patientQuery = `patient: patient(id: $id) {
          title
          prename
          name
          dob
          no
          sex
          diseases
          permissions {
            canInspect
          }
        }`
      } else {
        this.patient = {}
      }

      // Build user permissions query
      let permissionQueries = ''
      for (let userPermission of Object.keys(this.userPermissions)) {
        permissionQueries += `${userPermission}: authorization(
          action: "${ACTION}"
          subject: "${userPermission}"
        ) {
          permitted
        }`
      }

      const {
        data: { patient: patient, ...permissions },
      } = await query({
        query: gql`
          query Nav${queryParams} {
            ${patientQuery}
            ${permissionQueries}
          }
        `,
        variables: queryVars,
      })

      if (this.patient) {
        this.patient = {
          id: this.patientId,
          info: patient,
        }
      }

      for (let permission of Object.entries(permissions)) {
        this.userPermissions[permission[0]] = permission[1].permitted
      }
    },
    setNavigationState() {
      // The profile nav behavior logic is extracted from navigations.js
      // TODO: Refactor the whole nav once it's fully ported to vue

      const layoutElement = document.querySelector('.ui-layout')
      const profileLinks = document.querySelector('[data-flyout="profile"]')
      const profileNavTrigger = document.querySelector('#profile')
      const profileLinksList = profileLinks
        .querySelector('.navigation__list')
        .querySelectorAll('.navigation__link')

      profileLinks.classList.remove(
        NAV_FLYOUT_ACTIVE,
        NAV_FLYOUT_ACTIVE_WITH_OVERLAY
      )

      if (navigation.isPageExistInFlyout(profileLinksList)) {
        profileLinks.classList.add(NAV_FLYOUT_ACTIVE)
        layoutElement.classList.add('ui-layout--narrow')
        profileNavTrigger.classList.add('navigation__link--disabled')
      }
    },
    async getNavInfo() {
      await this.getPermissions()
      this.setNavigationState()
    },
  },
}
</script>
