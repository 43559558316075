import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { logErrorMessages } from '@vue/apollo-util'
import introspectionQueryResultData from '@/graphql/generated/fragmentTypes.json'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: (operation) => {
    // You should use an absolute URL here
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV !== 'production') {
      return `/denteo/graphql?${operation.operationName}`
    } else {
      return `/denteo/graphql`
    }
  },
})

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
}

// Cache implementation
const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
})

// Handle errors
const errorLink = onError((error) => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    logErrorMessages(error)
  }
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
  defaultOptions,
})
