import RestEntity from './rest-entity'

class Feed extends RestEntity {
  entityName = 'feeds'

  get collectionName() {
    return this.entityName
  }
}

export default new Feed()
