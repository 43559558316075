import { post } from '@/libs/axiosWrapper'
import { print } from 'graphql/language/printer'

const request = async ({ query, variables = {} }) => {
  const response = await post('/denteo/graphql', {
    query: print(query),
    variables,
  })

  return response
}

const query = ({ query, variables = {} }) => request({ query, variables })

const mutate = ({ mutation: query, variables = {} }) =>
  request({ query, variables })

export { query, mutate }
