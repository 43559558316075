import {
  SET_TREATMENT_FILLING_ASSISTANT,
  SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT,
  SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT,
  SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT,
  SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT,
} from '../../mutations-types'
import clinicalRecordService from '@/services/clinical-record'

/**
 *  Set treatment filling assistant record form value object
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - filling assistant record form value
 * @return {Object} filling assistant record form value
 */
export function setTreatmentAction({ commit }, payload) {
  commit(SET_TREATMENT_FILLING_ASSISTANT, payload)
}

/**
 *  Set selected surface areas
 * @param {function} commit - The vuex commit function
 * @param {Array} payload - Selected surface areas
 * @return {Array} Selected surface areas
 */
export function setSelectedSurfaceAreasAction({ commit }, payload) {
  commit(SET_SELECTED_SURFACE_AREAS_FILLING_ASSISTANT, payload)
}

export function setMostUsedConfigLookupTableAction({ commit }, payload) {
  commit(SET_MOST_USED_CONFIG_LOOKUP_TABLE_FILLING_ASSISTANT, payload)
}

export function setPrefillButtonHasBeenClickedAction({ commit }, payload) {
  commit(SET_PREFILL_BUTTON_HAS_BEEN_CLICKED_FILLING_ASSISTANT, payload)
}

/**
 *  Set filling assistant record object
 * @param {function} commit - The vuex commit function
 * @param {Object} payload - filling assistant record object
 * @return {Object} Filling assistant record
 */
export function fillingAssistantDefaultRecordAction({ commit }, payload) {
  const record = clinicalRecordService.defaultRecordFillingAssistant({
    patient_id: payload.patient_id,
    form_values: payload.form_values,
  })
  commit(SET_FILLING_ASSISTANT_RECORD_FILLING_ASSISTANT, record)
}

/**
 *  Record treatment
 * @param {Object} state - The vuex state function
 * @return {Promise}
 */
export async function recordTreatmentAction({ state }) {
  // TODO:
  // we should probably sanitize the data before persisting into DB:
  // array like "products"=>[{"name"=>nil}, {"name"=>""}, {"name"=>""}, {"name"=>""}] should not make it into the DB
  // maybe we can do this with one or multiple getters?
  // The BE needs a fix sturcutre, so the "products" key should remina in every record.
  // The BE needs to know a definitive pattern that represents an empty record. I suggest "products"=>[{"name"=>nil}]
  // This would mean, to simply filter out products with empty string as name, i.e. {"name" = ""}
  await clinicalRecordService.save(state.fillingAssistantRecord)
}
