
<v-card :flat="flat" :class="[mb, { flat: flat }]">
  <header
    class="d-flex flex-wrap d-print-block mt-2-print align-center gap"
    :class="{ 'header-padding': !flat }"
  >
    <div>
      <slot name="title">
        <h5 class="text-h5">
          <div v-if="title" class="print-hidden">
            {{ $t(title) }}
          </div>
          <h5
            v-if="printableTitle || title"
            class="text-h5 hidden d-print-block"
          >
            {{ printableTitle || $t(title) }}
          </h5>
        </h5>
      </slot>
      <slot name="subtitle">
        <h6 v-if="subtitle" class="text-h5 text--disabled">
          {{ subtitle }}
        </h6>
      </slot>
    </div>
    <v-spacer />
    <div class="d-flex flex-wrap align-center gap d-print-block">
      <slot name="actions" />
    </div>
  </header>
  <slot />
</v-card>
