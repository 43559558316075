const numberWithDelimiter = (value, decimals = 2) => {
  return new Intl.NumberFormat('de-CH', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value)
}

const numberToCurrency = (value, currency = 'CHF', locale = 'de-CH') => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    value
  )
}

export { numberWithDelimiter, numberToCurrency }
