import { columnTypes, columnTypeGroups } from '../types/calendar'
/**
 * Set json stringified setting with localStore module.key structure
 *
 * @param {String} module the module of the application the setting is to store for
 * @param {String} key The sub key within that module
 * @param {any} value any value that can be converted to JSON
 */
export const set = (module: string, key: string, value: any): void => {
  localStorage.setItem(`setting.${module}.${key}`, JSON.stringify(value))
}

/**
 * get localStore setting json parsed
 *
 * @param {String} module the module of the application the setting is to store for
 * @param {String} key The sub key within that module
 */
export const get = (
  module: string,
  key: string,
  defaultValue: any = null
): any => {
  if (!key) {
    throw new Error('Must have key for module.key')
  }
  let item = localStorage.getItem(`setting.${module}.${key}`)

  if (!item) {
    // Key used to be just "columns.selection", now it's location specific: "columns.selection.4" (includes the location ID)
    // Legacy handling for when column selection setting was not location dependent
    if (key.indexOf('columns.selection') !== -1) {
      item = localStorage.getItem(`setting.${module}.columns.selection`)
    }
  }

  try {
    return item ? JSON.parse(item) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

/**
 * Delete a local store setting key
 *
 * @param {String} module the module of the application the setting is to store for
 * @param {String} key The sub key within that module
 */
export const remove = (module: string, key: string): void => {
  localStorage.removeItem(`setting.${module}.${key}`)
}

export const getColumnSelection = (
  columnSelectionKey: string,
  defaultValue: any = {
    people: { notSelected: null, group: columnTypeGroups.default },
    rooms: { notSelected: null, group: columnTypeGroups.default },
    type: columnTypes.people,
  }
): any => get('calendar', columnSelectionKey, defaultValue)

export const setColumnSelection = (
  columnSelectionKey: string,
  params: any = {}
): void => {
  const savedOrDefault = getColumnSelection(columnSelectionKey)
  set('calendar', columnSelectionKey, { ...savedOrDefault, ...params })
}

const customViewTypeKey = 'customViewType'
export const getCustomViewType = (defaultValue: any = null): any =>
  get('calendar', customViewTypeKey, defaultValue)

export const setCustomViewType = (customViewType: any): void =>
  set('calendar', customViewTypeKey, customViewType)

const bidsModuleName = 'bids'
const relativeDateKey = 'relativeDateId'
const setRelativeDateId = (id: any): void =>
  set(bidsModuleName, relativeDateKey, id)
const getRelativeDateId = (): any => get(bidsModuleName, relativeDateKey)

/**
 *
 * @param uiContext key for UI in which the dialog exists.
 * @example
 * `Sample` or `Bid`
 * @returns Returns whether Dialog should appear
 */
export const getDialogRecurrence = (uiContext: string): boolean => {
  const state = get('dialog', uiContext)
  if (state === null) {
    return true
  }
  return state
}
/**
 *
 * @param uiContext key for UI in which the dialog exists.
 * @param state
 */
export const setDialogRecurrence = (
  uiContext: string,
  state: boolean
): void => {
  set('dialog', uiContext, state)
}

export default {
  set,
  get,
  remove,
  getColumnSelection,
  setColumnSelection,
  getCustomViewType,
  setCustomViewType,
  setRelativeDateId,
  getRelativeDateId,
  getDialogRecurrence,
  setDialogRecurrence,
}
