export default function () {
  const path = window.location.pathname
  if (
    path.startsWith('/patients/') &&
    !(path === '/patients/new') &&
    !path.includes('without_recall') &&
    !path.includes('export')
  ) {
    return path.split('/')[2]
  } else {
    return null
  }
}
