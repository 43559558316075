
<fragment>
  <slot v-if="readOnly">
    {{ value || placeholder }}
  </slot>
  <v-menu
    v-if="!readOnly"
    v-model="menuOpen"
    bottom
    offset-y
    :close-on-content-click="false"
    small
    persistent
    @keydown.esc="cancel"
  >
    <template #activator="{ on }">
      <span
        class="editable-area"
        :class="{
          'editable-area-active': menuOpen,
          placeholder: !value,
        }"
        v-on="on"
      >
        <slot v-if="value">
          {{ value }}
        </slot>
        <slot v-if="!value" name="placeholder">
          {{ $t(placeholder) }}
        </slot>
        <slot name="icon"></slot>
      </span>
    </template>
    <d-form
      v-if="menuOpen"
      :id="id"
      ref="form"
      :validation="{ editable: validation }"
      :form-data.sync="form"
      @valid-submit="save"
    >
      <template #default="{ valid }">
        <v-card>
          <v-card-text>
            <slot name="input">
              <component
                :is="inputComponent"
                single-line
                autofocus
                name="editable"
                v-bind="inputComponentProps"
                @keydown.enter="save"
              />
            </slot>
          </v-card-text>
          <v-card-actions class="pt-0 pb-4 px-4">
            <v-spacer />
            <d-btn role="secondary" label="cancel" @click="cancel" />
            <d-btn
              type="submit"
              :disabled="!valid"
              :form="id"
              label="save"
              @click="save"
            />
          </v-card-actions>
        </v-card>
      </template>
    </d-form>
  </v-menu>
</fragment>
