<template>
  <div class="empty">
    <div class="image" v-html="empty" />
  </div>
</template>

<script>
import empty from '../../../images/empty.svg'

export default {
  data() {
    return {
      empty,
    }
  },
}
</script>
<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  padding: 36px;

  .image {
    width: 204px;
    height: 204px;
  }
}
</style>
