const dInputComponentMixin = {
  inject: {
    dForm: {
      default: null,
    },
  },
  props: {
    value: {
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    hint: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
  },
  created() {
    this.dForm && this.dForm.registerInput(this.name, this)
  },
  destroyed() {
    this.dForm && this.dForm.unregisterInput(this.name)
  },
  computed: {
    fieldErrors() {
      const field = this.vuelidate
      if (!field || !field.$dirty) {
        return
      }
      const vuelidateErrors = field.$dirty
        ? Object.entries(field.$params)
            .filter(([key]) => !field[key])
            .map(([key, param]) => {
              const translationKey = param && param.type ? param.type : key
              return this.$t(`validationError.${translationKey}`, param)
            })
        : []

      const allErrors = [
        ...vuelidateErrors,
        ...this.dForm.additionalFieldErrors(this.name),
      ]

      return allErrors.length > 0 ? allErrors : null
    },
    model: {
      get() {
        if (this.isVuelidateField) {
          return this.vuelidate.$model
        }

        return this.value
      },
      set(value) {
        if (this.isVuelidateField) {
          this.vuelidate.$model = value
        }
        this.$emit('input', value)
      },
    },
    defaultInputBindings() {
      const {
        required,
        translatedLabel,
        placeholder,
        autofocus,
        fieldErrors,
        solo,
        hint,
        hideDetails,
        readonly,
      } = this
      return {
        required,
        label: translatedLabel,
        placeholder,
        disabled: this.isDisabled,
        readonly,
        autofocus,
        clearable: this.isClearable,
        solo,
        flat: solo,
        autocomplete: 'off',
        errorMessages: fieldErrors,
        filled: true,
        hint,
        persistentHint: !!hint,
        hideDetails,
      }
    },
    defaultHandlers() {
      return {
        change: (value) => this.triggerChange(value),
      }
    },
    isVuelidateField() {
      return this.name && this.value === undefined && this.vuelidate
    },
    translatedLabel() {
      return this.$t(this.label)
    },
    vuelidate() {
      return this.dForm && this.dForm.field(this.name)
    },
    isDisabled() {
      return (
        this.disabled ||
        (this.dForm &&
          !!this.dForm.readonlyFields.find((field) => field === this.name)) ||
        (this.dForm && this.dForm.formDisabled())
      )
    },
    isClearable() {
      return this.clearable && !this.isDisabled
    },
  },
  methods: {
    triggerChange(value) {
      this.isVuelidateField && this.dForm.triggerChange()
      this.$emit('change', value)
    },
    focus() {
      const inputElement = this.$el.querySelector('input, select, textarea')
      if (inputElement) {
        setTimeout(() => {
          inputElement.nodeName == 'SELECT'
            ? inputElement.select()
            : inputElement.focus()
        }, 0)
      } else {
        throw new Error(`no input field to focus found for field: ${this.name}`)
      }
    },
    submitForm() {
      this.isVuelidateField && this.dForm.submit()
    },
  },
}

export default dInputComponentMixin
