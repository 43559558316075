import { SHOW_CONFIRMATION } from '../../mutations-types'

/**
 *  Show 2FA modal confirmation status
 * @param {function} commit - The vuex commit function
 * @param {Boolean} payload - Show 2FA modal confirmation status
 * @return {Boolean} Show 2FA modal confirmation status
 */
export async function setShowConfirmationAction({ commit }, payload) {
  commit(SHOW_CONFIRMATION, payload)
}
