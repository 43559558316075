import { SHOW_CONFIRMATION } from '../../mutations-types'

import * as actions from './actions'
import * as getters from './getters'

const mutations = {
  [SHOW_CONFIRMATION](state, payload) {
    state.showConfirmation = payload
  },
}

const state = () => ({
  showConfirmation: false,
})

export default {
  namespaced: true,
  mutations,
  actions,
  getters,
  state,
}
