
<div class="document-search">
  <d-autocomplete
    ref="documentSearch"
    v-model="model"
    v-click-outside="blur"
    :loading="isLoading"
    :search-input.sync="query"
    :items="documents"
    :menu-props="{ maxHeight, minWidth: '365px' }"
    filled
    item-text="label"
    item-value="id"
    :placeholder="placeholder || $t('patient_document_placeholder')"
    :hide-no-data="hideNoData"
    :no-data-text="$t('documents_empty')"
    append-icon=""
    color="#949494"
    height="56"
    class="document-search__input"
    background-color="#F0F0F0"
    clearable
    auto-select-first
    open-on-clear
    no-filter
    v-bind="{ ...defaultInputBindings, ...$attrs }"
    v-on="defaultHandlers"
    @keydown.esc="$emit('cancel')"
  >
    <template #item="{ item }">
      <div class="d-flex flex-column" style="z-index: 1">
        <div
          style="font-size: 16px; padding-bottom: 4px"
          v-html="highlight(item.patientFullName, { normalize: true })"
        />

        <div class="document-info" v-html="highlight(item.documentInfo)" />

        <div class="recipient" v-html="highlight(item.recipientPerson)" />
      </div>
    </template>
  </d-autocomplete>
</div>
